<template>
  <div ref="pageRef" class="container-fluid pcg-page-container">
    <vue-progress-bar/>
    <div class="container-fluid pcg-page-container"
         :class="{ 'my-pcg-header': !signedIn && isLoginPath, 'my-page-container': !signedIn && isLoginPath }">
      <layout-header :logo="instance ? instance.menuLogo: null"
                     :negative-logo="instance ? instance.menuLogoNegative : null"
                     :logoContainerClasses="sidebarMenuClasses" class="prk-header">
        <template v-slot:upper>
          <div class="upper-header-container">
            <pcg-header-contrast class="icon-padding header-fonts"/>
            <pcg-header-fonts class="icon-padding header-fonts d-inline"/>
            <pcg-language-selector :supportedLanguages="supportedLanguages"
                                   class="d-flex align-items-center"
                                   @localeChange="reloadPage($event)"/>
          </div>
        </template>
        <template v-slot:bottom>
          <pcg-header-navbar :class="['header-navbar', { 'hide-menu-btn': renderedNavigationMenuLinksCount === 0 }]">
            <div v-if="signedIn" class="navbar-bottom-container d-flex align-items-center">
              <div class="navbar-bottom-background"/>
              <pcg-header-user-name class="username" :user-name="fullName"/>
              <pcg-header-fonts class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-header-contrast class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-header-messages :current-role="currentRole"
                                   :content-path-per-message-type="contentPathPerMessageType"
                                   :messages="formattedMessagesData"
                                   :unreadCount="messages.unreadCount"
                                   class="messages"
                                   @hidden="markAllMessagesAsRead"/>
              <pcg-header-notifications :current-role="currentRole"
                                        :content-path-per-notification-type="contentPathPerNotificationType"
                                        :notifications="formattedNotificationsData"
                                        :unreadCount="notifications.unreadCount"
                                        class="notifications"
                                        @hidden="markAllNotificationsAsRead"/>
              <pcg-header-profile-button :currentRole="currentRole"
                                         :roles="selectableRoles"
                                         :supportedLanguages="supportedLanguages"
                                         :avatar="avatar"
                                         @changeRole="changeCurrentRole"
                                         @localeChange="reloadPage"
                                         @logout="logout"/>
            </div>
            <div v-else class="pcg-toolbar-items pcg-txt-right d-flex align-items-center">
              <!--                        <pcg-header-fonts class="icon-padding header-fonts d-inline d-md-none"/>-->
              <pcg-header-contrast class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-language-selector :supportedLanguages="supportedLanguages"
                                     class="d-inline-flex d-md-none align-items-center"
                                     @localeChange="reloadPage"/>
              <div class="d-none d-md-block">
                <pcg-btn @click="login" size="small" class="login-button ml-auto">
                  {{ $t('components.prk_header_navbar.login_btn') }}
                </pcg-btn>
              </div>
            </div>
          </pcg-header-navbar>
        </template>
      </layout-header>
      <b-row class="pcg-body-container">
        <pcg-navigation-menu ref="navigationMenu"
                             :current-role="currentRole"
                             class="navigation-menu"
                             :signed-in="signedIn"
                             :links="links"
                             :sidebar-menu-classes="sidebarMenuClasses"
                             :class="{ 'prk-login-menu': isLoginPath }">

        </pcg-navigation-menu>
        <div class="col" :class="{ 'small-margin': !menuOpen, 'main-content-margin': !isLoginPath }">
          <div class="row">
            <pcg-menu-switcher v-show="!isLoginPath" class="menu-switcher"/>
            <div class="pcg-content-container">
              <prk-page-background-cover/>
              <div v-if="isLoginPath && !signedIn" class="mobile-login-buttons-container d-block d-md-none">
                <pcg-btn @click="login" size="small" class="login-button ml-auto">
                  {{ $t('components.prk_header_navbar.login_btn') }}
                </pcg-btn>
                <span class="buttons-separator">{{ $t('general.or') }}</span>
                <pcg-btn @click="login" size="small" variant="additional" class="login-button mr-0">
                  {{ $t('components.prk_header_navbar.register_btn') }}
                </pcg-btn>
              </div>
              <transition name="slide" mode="out-in">
                <router-view/>
              </transition>
            </div>
          </div>
          <div class="row align-items-end">
            <!--          <layout-footer v-if="$route.path === '/'"-->
            <!--                         :upper-text="env.footerUpperText"-->
            <!--                         :bottom-text="env.footerBottomText"-->
            <!--                         :logo="require('../assets/images/logo.svg')"-->
            <!--                         :negative-logo="require('../assets/images/logo-negatyw.svg')"/>-->
          </div>
        </div>
      </b-row>
    </div>
    <prk-footer-navbar v-if="items && items.length > 0"/>
    <prk-open-id-front-channel-logout/>
  </div>
</template>

<script>
import PrkOpenIdFrontChannelLogout from '../components/PrkOpenIdFrontChannelLogout'
import NavigationMenuLinksMixin from '../mixins/navigation_menu_links'
import supportedLanguages from '../translations/supported_langs'
import PrkPageBackgroundCover from '../components/PrkPageBackgroundCover'
import PrkFooterNavbar from '../components/PrkFooterNavbar'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    PrkFooterNavbar,
    PrkPageBackgroundCover,
    PrkOpenIdFrontChannelLogout,
  },
  mixins: [NavigationMenuLinksMixin],
  errorCaptured () {
    this.$toastr.e(this.generalError)
  },
  data: function () {
    return {
      img: '../assets/images/nav_icon/syllabus.svg',
      isLoading: true,
      supportedLanguages,
      contentPathPerMessageType: {},
      contentPathPerNotificationType: {},
      renderedNavigationMenuLinksCount: 0,
      iconsNotSet: true,
      links: [
        {
          id: 1,
          name: this.$t('views.header.study_plan.title'),
          active: false,
          iconClass: 'fas fa-columns',
          path: 'study_plans_list_path',
          roles: ['admin', 'employer'],
          sub: [],
        },
        {
          id: 2,
          name: this.$t('views.header.courses.title'),
          active: false,
          path: 'courses_list_path',
          roles: ['admin', 'employer'],
          iconClass: 'courses-navigation',
          sub: [],
        },
        {
          id: 3,
          name: this.$t('views.header.matrices'),
          active: false,
          path: 'matrices_list_path',
          roles: ['admin', 'employer'],
          iconClass: 'matrices-navigation',
          sub: [],
        },
        {
          id: 4,
          name: this.$t('views.header.syllabus.list'),
          active: false,
          path: 'syllabuses_list_path',
          roles: ['admin', 'deanery', 'educator', 'employer', 'student'],
          iconClass: 'syllabus-navigation',
          sub: [],
        },
        {
          id: 5,
          name: this.$t('views.header.dictionary.title'),
          active: false,
          path: '',
          roles: ['admin', 'employer'],
          iconClass: 'fas fa-book',
          sub: [
            {
              id: 8,
              name: this.$t('views.header.dictionary.general_effects'),
              path: 'dictionaries_general_effects_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 6,
              name: this.$t('views.header.dictionary.fields'),
              path: 'dictionaries_fields_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 7,
              name: this.$t('views.header.dictionary.teaching_effects'),
              path: 'dictionaries_teaching_effects_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 9,
              name: this.$tc('views.header.dictionary.standard_effects', 2),
              path: 'dictionaries_standards_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 10,
              name: this.$t('views.header.dictionary.kind'),
              path: 'dictionaries_kinds_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 11,
              name: this.$t('views.header.dictionary.form_of_classes'),
              path: 'dictionaries_form_of_classes_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 12,
              name: this.$t('views.header.dictionary.form_of_credits'),
              path: 'dictionaries_form_of_credits_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 13,
              name: this.$t('views.header.dictionary.category'),
              path: 'dictionaries_categories_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 14,
              name: this.$t('views.header.dictionary.literatures'),
              path: 'dictionaries_literatures_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 15,
              name: this.$t('views.header.dictionary.subject_names'),
              path: 'dictionaries_subject_names_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 16,
              name: this.$t('views.header.dictionary.types'),
              path: 'dictionaries_types_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 17,
              name: this.$t('views.header.dictionary.profiles'),
              path: 'dictionaries_profiles_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 18,
              name: this.$t('views.header.dictionary.specialities'),
              path: 'dictionaries_specialities_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
            {
              id: 19,
              name: this.$t('views.header.dictionary.faculties_and_courses'),
              path: 'dictionaries_faculties_path',
              roles: ['admin', 'employer'],
              active: false,
              sub: [],
            },
          ],
        },
        // {
        //   id: 17,
        //   name: this.$t('views.header.personnel_list'),
        //   active: false,
        //   path: 'personnel_list_path',
        //   roles: ['admin', 'educator'],
        //   iconClass: 'fas fa-user',
        //   sub: [],
        // },
        {
          id: 18,
          name: this.$t('views.header.permission_list'),
          active: false,
          path: 'permission_list_path',
          roles: ['admin'],
          iconClass: 'permission-navigation',
          sub: [],
        },
        {
          id: 19,
          name: this.$t('views.header.syllabus_templates.list'),
          active: false,
          path: 'syllabuses_templates_list_path',
          roles: ['admin'],
          iconClass: 'templates-navigation',
          sub: [],
        },
      ],
      navigationIcons: [
        {
          className: 'courses-navigation',
          iconName: 'atom',
        },
        {
          className: 'matrices-navigation',
          iconName: 'matrices',
        },
        {
          className: 'syllabus-navigation',
          iconName: 'syllabus',
        },
        {
          className: 'permission-navigation',
          iconName: 'permission',
        },
        {
          className: 'templates-navigation',
          iconName: 'templates',
        },
      ],
    }
  },
  computed: {
    ...mapState('error', ['generalError']),
    ...mapGetters('footerNavbar', ['items']),
    ...mapGetters('auth', ['fullName', 'avatar', 'currentRole', 'roles', 'signedIn']),
    ...mapGetters('notifications', ['messages', 'notifications']),
    ...mapGetters('sidebarMenu', ['sidebarMenuClasses']),
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('sidebarMenu', ['navigationMenuClasses']),
    ...mapGetters('instance', ['instance']),
    ...mapGetters('environment', ['env', 'status']),
    formattedMessagesData () {
      return []
    },
    formattedNotificationsData () {
      return []
    },
    selectableRoles () {
      return this.roles.includes('student') ? _.difference(this.roles, ['graduate']) : this.roles
    },
    isLoginPath () {
      return ['login_path', 'root_path'].includes(this.$route.name)
    },
    logo () {
      return this.$route.name !== 'root_path' ? 'logo.svg' : ''
    },
  },
  watch: {
    '$i18n.locale' (newValue) {
      moment.locale(newValue)
    },
    isLoading (newValue) {
      if (!newValue && this.iconsNotSet) {
        this.navigationIcons.forEach(navIcon => {
          this.setNavigationImage(navIcon.className, navIcon.iconName)
        })
      }
    },
    async signedIn () {
      await this.$nextTick()
      this.navigationIcons.forEach(navIcon => {
        this.setNavigationImage(navIcon.className, navIcon.iconName)
      })
    },
  },
  beforeCreate () {
    if (!localStorage.getItem('locale')) localStorage.setItem('locale', 'pl')
    this.$i18n.locale = localStorage.getItem('locale')
  },
  created () {
    this.getEnv()
    const interval = setInterval(() => {
      const syllabusPdfLink = this.$cookies.get('link')
      if (this.signedIn && syllabusPdfLink) {
        window.location.href = syllabusPdfLink
        this.$cookies.remove('link')
        clearInterval(interval)
      }
    }, 1000)

    this.$store.dispatch('header/setSiteTitle', 'Sylabus')
    this.$store.dispatch('header/clearHeader')
    if (this.signedIn) {
      this.fetchUser()
    }
    const link = this.links.find(link => link.path === this.$route.path)
    link && (link.active = true)
    this.setGeneralError(this.$t('general.check_errors'))
  },
  beforeMount () {
    if (!this.$store.getters['instance/instance']) {
      this.$store.dispatch('instance/setInstance', null)
    }
  },
  mounted () {
    this.$store.dispatch('locale/setInitialLocale', this.supportedLanguages)
    setTimeout(() => {
      if (!this.signedIn && process.env.RAILS_ENV !== 'development') {
        window.location = '/users/auth/openid_connect'
      }
    }, 1000)
    setTimeout(() => {
      document.getElementById('app-loader').style.display = 'none'
      this.isLoading = false
    }, 2000)

    this.navigationIcons.forEach(navIcon => {
      this.setNavigationImage(navIcon.className, navIcon.iconName)
    })
  },
  beforeUpdate () {
    this.$nextTick(() => {
      const navMenu = this.$refs.navigationMenu
      this.renderedNavigationMenuLinksCount = navMenu ? navMenu.$el.querySelectorAll('.list-group > li').length : 0
    })
  },
  methods: {
    ...mapActions('error', ['setGeneralError']),
    async setNavigationImage (className, iconName) {
      const link = this.links.find(link => {
        return link.iconClass === className
      })

      if (!link.roles.includes(this.currentRole) || !this.signedIn) return

      await this.$nextTick()
      if (this.$refs.pageRef) {
        this.iconsNotSet = false
        const navigationItem = document.getElementsByClassName(className)[0]
        const navigationIcon = document.createElement('img')
        navigationIcon.setAttribute('src', require(`../assets/images/nav_icon/${iconName}.svg`))
        navigationItem.appendChild(navigationIcon)
      }
    },
    reloadPage (locale) {
      localStorage.setItem('locale', locale)
      this.$router.go(0)
    },
    changeCurrentRole (role) {
      this.$store.dispatch('auth/setCurrentRole', role)
      this.$router.push({ name: 'root_path' })
    },
    login () {
      window.location = '/users/auth/openid_connect'
    },
    logout () {
      this.$store.dispatch('auth/signOut')
        .then(res => {
          const idToken = res.data.idToken
          window.location = `${this.env.prkOpEndSessionEndpoint}?id_token_hint=${idToken}&post_logout_redirect_uri=${this.env.serwerAddressWithProtocol}`
        })
        .catch(error => {
          this.$toastr.e(error.response.data.message)
        })
    },
    fetchUser () {
      this.$store.dispatch('auth/fetchUser')
        .then(() => {

        })
        .catch(error => {
          console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    markAllMessagesAsRead () {

    },
    markAllNotificationsAsRead () {

    },
    async getEnv () {
      await this.$store.dispatch('environment/fetchEnv')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars";

::v-deep {
  .logo {
    width: 100%
  }
  .logo-negatyw {
    filter: grayscale(100%) invert(100%);
  }
}

.my-pcg-header {
  ::v-deep .pcg-header-container {
    .logo img {
      width: 100%
    }

    .pcg-logo-container {
      display: none !important;
    }
  }
}

.my-page-container {
  ::v-deep .main-content-margin {
    padding-left: 0 !important;
  }
}

::v-deep {
  .navigation-item.active {
    background-color: $pcg-dark-blue !important;
    color: $pcg-aqua-blue !important;
  }

  .active:first-child img {
    filter: invert(56%) sepia(46%) saturate(3681%) hue-rotate(165deg) brightness(99%);
  }

  .list-group-item-action:first-child:hover img {
    filter: brightness(0) invert(1);
  }

  .list-group-item-action.active:hover span {
    color: $pcg-white;
  }
}

.mobile-login-buttons-container {
  position: fixed;
  background-color: $pcg-white;
  top: 65px;
  right: 0;
  padding-right: 20px;
  width: 100%;
  text-align: right;
  z-index: 1;

  button {
    margin-top: 0;
  }
}

@media (min-width: $screen-sm-max) {
  ::v-deep .pcg-header-container {
    .header-background:not(.show) ~ .col {
      .pcg-top-container, .pcg-logged-user-container {
        z-index: 2;
      }

      .pcg-background-transparent {
        .navbar-bottom-container {
          position: relative;
        }

        .navbar-bottom-background {
          z-index: -1;
          display: block;
          background: $pcg-white;
          opacity: .5;
          position: absolute;
          top: -40px;
          left: -30px;
          width: calc(100% + 60px);
          height: 115px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

.header-title {
  margin-bottom: 20px;
  display: block;
  padding-top: 20px;
}

.pcg-btn-sidebar-menu {
  height: 45px;
  width: 25px;
  align-items: center;
}

@media screen and (min-width: 1051px) {
  .header-title {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars';

/deep/ .login-button {
  width: 120px;
}

.navigation-item {
  padding: 0.7rem 1rem 1rem 3rem;
  cursor: pointer;

  &.active {
    background: $pcg-aqua-blue;
    color: $pcg-white;
  }
}

.menu-switcher {
  background: $pcg-aqua-blue;
}

.pcg-btn-sidebar-menu {
  height: 45px;
  width: 25px;
  align-items: center;
}

.header-title {
  margin-bottom: 20px;
  display: block;
  padding-top: 20px;
}

.icon-padding {
  color: $main-active-color;
  padding-right: 10px;
}

.messages, .notifications {
  color: $main-active-color;
  margin-right: 18px;
}

.username {
  margin-right: 18px;
}

.upper-header-container {
  display: flex;
  align-items: center;
  height: 40px;
}

.header-fonts {
  padding-top: 2px;
}

.header-navbar.hide-menu-btn {
  ::v-deep .pcg-menu-btn {
    display: none
  }
}

@-moz-document url-prefix() {
  .pcg-btn-sidebar-menu {
    height: 46px !important;
  }
}

@media screen and (min-width: 1051px) {
  .header-title {
    display: none;
  }
}

@media (max-width: $screen-md-max) {
  .icon-padding {
    padding-right: 18px;
  }
}
</style>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars-contrast';

.contrast {
  .mobile-login-buttons-container {
    background-color: $pcg-white;
  }

  ::v-deep {
    .navigation-item.active {
      background-color: $pcg-dark-blue !important;
      color: $pcg-aqua-blue !important;
    }
    .list-group-item-action.active:hover span {
      color: $pcg-white;
    }
  }

  .icon-padding {
    color: $main-active-color;
  }

  .messages, .notifications {
    color: $main-active-color;
  }

  .pcg-header-container {
    .navbar-bottom-background {
      background-color: $pcg-white !important;
    }
  }

  @media (min-width: $screen-sm-max) {
    ::v-deep .pcg-header-container {
      .header-background:not(.show) ~ .col {
        .pcg-background-transparent {
          .navbar-bottom-background {
            background: $pcg-white;
          }
        }
      }
    }
  }

  .navigation-item {
    &.active {
      background: $pcg-aqua-blue;
      color: $pcg-white;
    }
  }

  .menu-switcher {
    background: $pcg-aqua-blue;
  }

  .icon-padding {
    color: $main-active-color;
  }

  .messages, .notifications {
    color: $main-active-color;
  }

  .prk-header {
    /deep/ .header-background {
      background: transparent !important;
    }
  }
}

</style>
