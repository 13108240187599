export const components = {
  confirm_assignment_educators: {
    text: 'Wybrane ustawienia nadpiszą istniejące. Czy chcesz zapisać?',
  },
  new_changes: {
    text: 'Wykryto zmiany w formularzu. Czy chcesz je zapisać?',
  },
  pcg: {
    buttons: {
      add_section: 'Dodaj sekcje',
      copy: 'Kopiuj',
      delete: 'Usuń',
      delete_course: 'Usuń kierunek',
      delete_matrix: 'Usuń matryce',
      delete_matrices: 'Usuń matrycę',
      delete_plan: 'Usuń plan',
      delete_syllabus: 'Usuń sylabus',
      delete_template: 'Usuń szablon',
      delete_templates: 'Usuń szablony',
      download_doc: 'Pobierz plik DOC',
      download_pdf: 'Pobierz plik PDF',
      edit: 'Edytuj',
      edit_course: 'Edytuj kierunek',
      edit_plan: 'Edytuj plan',
      generated_syllabuses: 'Wygenerowane sylabusy',
      pin: 'Przypnij do',
      save: 'Zapisz',
      show_options: 'Opcje',
    },
  },
  prk_beam: {
    approve_and_send: 'Zatwierdź i wyślij',
    back_to_edit: 'Wróć do edycji',
    back_to_edit_template: 'Wróć do edycji szablonu',
    preview_template: 'Podgląd szablonu',
    publish_template: 'Publikuj szablon',
    show_to_fill: 'Pokaż do wypełnienia',
    show_filled: 'Pokaż wypełnione',
  },
  prk_courses: {
    academic_year: 'Rok akademicki',
    back: 'Wróć do Kierunków',
    characteristics_effects: 'Efekty charakterystyk',
    characteristics_of_the_sciences: 'Charakterystyki nauk',
    download: 'Pobierz efekty z poprzednich lat',
    clo: 'Kierunkowe efekty uczenia się',
    download_effects: 'Pobierz efekty z poprzednich lat',
    new_effect: '+ Nowy efekt kierunkowy',
    reference: 'Odniesienie do charakterystyk ogólnych',
    settings: {
      create: 'Utwórz standard na podstawie tego kierunku',
      download: 'Pobierz standard',
      download_percentage_share: 'Procentowy udział',
      download_percentage_share_doc: 'Procentowy udział DOC',
      download_percentage_share_pdf: 'Procentowy udział PDF',
      download_printout_coverage_general_effects_by_course_effects: 'Pokrycie efektów ogólnych przez kierunkowe',
      download_printout_coverage_general_effects_by_course_effects_doc: 'Pokrycie efektów ogólnych przez kierunkowe DOC',
      download_printout_coverage_general_effects_by_course_effects_pdf: 'Pokrycie efektów ogólnych przez kierunkowe PDF',
      download_relating_course_effects_to_general_effects: 'Odniesienie efektów kierunkowych do ogólnych',
      download_relating_course_effects_to_general_effects_doc: 'Odniesienie efektów kierunkowych do ogólnych DOC',
      download_relating_course_effects_to_general_effects_pdf: 'Odniesienie efektów kierunkowych do ogólnych PDF',
      download_table_general_effects_for_course: 'Tabela efektów ogólnych dla kierunku studiów',
      download_table_general_effects_for_course_doc: 'Tabela efektów ogólnych dla kierunku studiów DOC',
      download_table_general_effects_for_course_pdf: 'Tabela efektów ogólnych dla kierunku studiów PDF',
      edit: 'Edytuj szczegóły',
    },
    standard: 'Standard kształcenia',
    symbol: 'Symbol',
    tooltip: 'Do przedmiotu dodano nowe efekty kierunkowe',
    update: 'Aktualizuj kierunki',
    update_info_1: 'Pojawiło się',
    update_info_2: 'zmian w kierunkach',
  },
  prk_dictionaries: {
    add_discipline: 'Dodaj dyscyplinę',
    assign_learning_outcomes: 'Przypisz efekty kierunkowe uczenia',
    back_to_courses: 'Wróć do kierunków',
    back_to_faculties: 'Wróć do wydziałów',
    back_to_fields: 'Wróć do dziedzin',
    characteristic: 'Charakterystyka',
    course_name: 'Nazwa kierunku',
    create_course: 'Stwórz kierunek',
    create_standard: 'Stwórz standard',
    edit_course: 'Edytuj kierunek',
    edit_standard: 'Aktualizuj standard',
    general_effects: 'Efekty ogólne',
    new_category: '+ Nowa kategoria',
    new_characteristic: '+ Nowa charakterystyka',
    new_characteristic_effect: '+ Nowy efekt',
    new_discipline: '+ Nowa dyscyplina',
    new_discipline_title: 'Nowa dyscyplina',
    new_field: '+ Nowa dziedzina',
    new_subjects_type: '+ Nowy rodzaj przedmiotów',
    return: 'Wróć do standardów',
    save_course: 'Zapisz kierunek',
    select_effects: 'Zaznacz efekty charakterystyki, które chcesz dodać',
    standard_name: 'Nazwa standardu',
  },
  prk_fields: {
    add_discipline: '+ Dodaj dyscyplinę',
    leading: 'Dyscyplina wiodąca',
  },
  prk_matrices: {
    checkbox: 'Dodaj semestry specjalnościowe i specjalizacyjne',
    add_effect: '+ Przypisz KEU',
    add_study_plan: 'Przypisz plan studiów',
    collapse_effect: 'Zwiń Efekty',
    generate_syllabuses: 'Generuj sylabusy',
    new_matrix: 'Stwórz nową matrycę',
    new_plan: '+ Nowy plan studiów',
    expand_effect: 'Rozwiń Efekty',
    return: 'Wróć do Listy Matryc',
    semester_type: 'Typ semestru',
    set_standard: 'Ustaw jako standardową',
    settings: {
      clo_effects_matrix: 'Matryca efektów KEU',
    },
    study_plan: 'Plany studiów',
    update: 'Regeneruj matrycę',
    update_info_2: 'zmian w matrycach',
  },
  prk_syllabus: {
    basic_information: {
      subject_code: 'Kod przedmiotu (opcjonalnie)',
      row_2: 'Nazwa przedmiotu / modulu',
      row_3: 'Nazwa jednostki realizującej przedmiot',
      row_4: 'Adres jednostki realizującej przedmiot',
      row_5: 'Adres mailowy jednostki realizującej przedmiot',
      row_6: 'Kierunek studiów',
      row_7: 'Poziom studiów',
      row_8: 'Profil',
      row_9: 'Forma studiów',
      row_10: 'Specjalność',
      row_11: 'Rok akademicki i semestr/y studiów',
      row_12: 'Rodzaj przedmiotu',
      language: 'Język wykładowy',
      row_14: 'Koordynator',
      leading_persons: 'Imię i nazwisko osoby prowadzącej/osób prowadzących',
    },
    beam: {
      back_to_edit: 'Wróć do edycji szablonu',
      create_course: 'Stwórz kierunek',
      publish: 'Publikuj szablon',
      preview: 'Podgląd szablonu',
      save: 'Zapisz',
      selected_elements: 'Zaznaczonych elementów',
    },
    buttons: {
      add_new_table_row: 'Dodaj nowy wiersz tabeli',
      add_element: 'Dodaj element',
      add_new_element: 'Dodaj nowy element',
      comments: 'Komentarze',
      coordinators: 'Koordynatorzy',
      delete: 'Usuń',
      delete_course: 'Usuń kierunek',
      delete_discipline: 'Usuń dyscyplinę',
      delete_effect: 'Usuń efekt',
      delete_elements: 'Usuń element | Usuń elementy',
      delete_plan: 'Usuń plan',
      delete_row: 'Usuń rząd',
      delete_rows: 'Usuń rzędy',
      delete_syllabus: 'Usuń sylabus',
      download_doc: 'Pobierz plik DOC',
      download_pdf: 'Pobierz plik PDF',
      download_excel: 'Pobierz plik Excel',
      edit: 'Edytuj',
      edit_effect: 'Edytuj efekt',
      edit_plan: 'Edytuj plan',
      edit_row: 'Edytuj rząd',
      edit_syllabus: 'Edytuj sylabus',
      generated_syllabuses: 'Wygenerowane sylabusy',
      new_subject_effect: 'Nowy efekt przedmiotowy',
      print_syllabus: 'Drukuj',
      send_email: 'Wyślij email',
      send_syllabus: 'Wyślij sylabus',
      show_options: 'Opcje',
      un_select_elements: 'Odznacz elementy',
    },
    errors: {
      cannot_delete: 'Nie można usunąć efektów kierunkowych przypisanych do zatwierdzonych sylabusów',
    },
    header: {
      academicYear: 'Rok Akademicki',
      check_education_effects: 'Sprawdź efekty uczenia',
      copy_from_previous_years: 'Skopiuj z poprzednich lat',
      during_education: 'Dane toku kształcenia',
      language: 'Język sylabusa',
      info_box: 'Szablon',
      logo: 'LOGOTYP UCZELNI',
      title: 'SYLABUS',
      training_cycle: 'dotyczy cyklu kształcenia',
      to_fill: 'Do wypełnienia',
    },
    list: {
      checkbox_header: 'Nazwa szablonu',
    },
    literature: {
      basic: 'Literatura podstawowa',
      supplementary: 'Literatura uzupełniająca',
    },
    modal: {
      literature: {
        author: 'Autor',
        title: 'Tytuł',
        publishing_house: 'Wydawnictwo',
        place_of_publication: 'Miejsce wydania',
        year: 'Rok',
        add_literature: 'Dodaj literaturę',
      },
    },
    new_element: {
      checkbox: {
        title: 'Checkbox',
        text_above: 'Opis nad checkboxem (opcjonalne)',
        name: 'Nazwa checkboxu',
        text_below: 'Opis pod checkboxem (opcjonalne)',
      },
      one_line: {
        title: 'Jedna linia tekstu',
        text_above: 'Opis nad polem tekstowym (opcjonalne)',
        name: 'Nazwa pola tekstowego',
        text_below: 'Opis pod polem tekstowym (opcjonalne)',
      },
      table: {
        title: 'Tabela',
        text_above: 'Opis nad tabelą (opcjonalne)',
        columns_number: 'Liczba kolumn',
        heading: 'Nagłówek kolumny',
        text_below: 'Opis pod tabelą (opcjonalne)',
      },
      text_field: {
        title: 'Pole tekstowe',
        text_above: 'Opis nad polem tekstowym (opcjonalne)',
        name: 'Nazwa pola tekstowego',
        text_below: 'Opis pod polem tekstowym (opcjonalne)',
      },
    },
    return: {
      general_effects: 'Wróć do listy efektów ogólnych',
      matrices_list: 'Wróć do listy matryc',
      plans_list: 'Wróć do Planów Studiów',
      template_list: 'Wróć do listy szablonów',
    },
    section: {
      additional_heading: 'Dodatkowa sekcja ',
      automatic_download: 'Pobieranie automatyczne',
      choose_element: 'Wybierz elementy, które mają znaleźć się w tej sekcji',
      description: 'Opis pod sekcją',
      fill_user: 'Do uzupełenia przez dydaktyka',
      heading: 'Nagłówek',
      new_section: '+ Nowa sekcja',
    },
    sections: {
      number_1_0: 'Podstawowe informacje o przedmiocie',
      number_1_1: 'Formy zajęć dydaktycznych, wymiar godzin i punktów ECTS',
      number_1_2: 'Sposób realizacji zajęć',
      number_1_3: 'Sposób zaliczenia przedmiotów',
      number_2: 'Wymagania wstępne',
      number_3: 'Cele, efekty uczenia się, treści programowe i stosowane metody dydaktyczne',
      number_3_1: 'Cele przedmiotu/modułu',
      number_3_2: 'Efekt kształcenia dla przedmiotu/modułu',
      number_3_3: 'Treści przedmiotowe',
      number_3_4: 'Metody dydaktyczne',
      number_4: 'Metody i kryteria oceniania',
      number_4_1: 'Sposoby weryfikacji efektów uczenia się',
      number_4_2: 'Warunki zaliczenia przedmiotu',
      number_5: 'Całkowity nakład pracy studenta',
      number_6: 'Praktyki zawodowe w ramach przedmiotu',
      number_7: 'Literatura',
    },
    setting: {
      add_description: 'Dodaj opis pod sekcją',
      hide_printout: 'Ukryj na wydruku',
      remove_description: 'Usuń opis pod sekcją',
      restore: 'Przywróć domyślną nazwę',
      show_printout: 'Pokaż na wydruku',
    },
    table: {
      author: 'Autor',
      category_name: 'Kategoria',
      course_code: 'Kod przedmiotu',
      course_name: 'Kierunek',
      academic_year: 'Rok akademicki',
      average_number_of_hours: 'Średnia liczba godzin na zrealizowanie aktywności',
      category: 'Kategoria',
      characteristic: 'Charakterystka | Charakterystyki',
      characteristics_gs: 'Charakt.Ogólne/Standard',
      characteristic_name: 'Nazwa',
      coordinator: 'Osoba koordynująca',
      en_description: 'Opis (ang)',
      value_0: 'Nagłówek 1',
      value_1: 'Nagłówek 2',
      value_2: 'Nagłówek 3',
      course: 'Kierunek',
      code: 'Kod przedmiotu',
      coordinating_person: 'Osoba koordynująca',
      course_code_info: 'Zgodnie z ustaleniami na wydziale',
      course_effects: 'KEU',
      department: 'Wydział',
      description: 'Opis',
      directional_learning_outcomes: 'Kierunkowe Efekty',
      discipline: 'Dyscyplina',
      ects: 'ECTS',
      // TODO powinno zmienić się też nazwę pola w javascripcie
      educational_outcome: 'Symbol efektu',
      effect_content: 'Treść efektu',
      effect_content_obj: 'Treść efektu dla przedmiotu (modułu)',
      effect_description: 'Opis efektu',
      faculty: 'Wydział',
      field: 'Dziedzina',
      field_exercises: 'Ćwiczenia terenowe',
      first_name: 'Imię',
      form: 'Forma',
      form_of_class: 'Forma zajęć',
      form_of_credit: 'Forma zaliczenia',
      form_of_classes: 'Formy zajęć',
      form_of_credits: 'Metody oceny',
      form_of_passing_the_subject: 'Forma zaliczenia przedmiotu',
      from_plan: 'Z toku',
      general_effects_reference: 'Odniesienie do efektów ogólnych',
      header: 'Nagłówek',
      hours: 'Godziny',
      keks: 'Odniesienie do efektów uczenia się standard/kierunkowe',
      kens: 'Odniesienie do efektów nauczycielskich',
      keu_year: 'Rocznik KEU',
      kind: 'Forma',
      laboratory_exercises: 'Ćwieczenia laboratoryjne',
      last_name: 'Nazwisko',
      leading_discipline: 'Dyscyplina wiodąca',
      learning_verification: 'Sposoby weryfikacji efektów uczenia się',
      lectures: 'Wykłady',
      lecturers: 'Prowadzący',
      level: 'Poziom',
      lp: 'Lp',
      methods_of_assessment_of_learning_outcomes: 'Metody oceny efektów kształcenia',
      name: 'Nazwa',
      name_tok_subject: 'Nazwa Toku/Planu',
      name_of_subject: 'Nazwa przedmiotu',
      name_of_term: 'Nazwa semestru',
      number_of_hours: 'Liczba godzin',
      percent: 'Procent',
      place_of_publication: 'Miejsce publikacji',
      profile: 'Profil',
      publishing_house: 'Wydawnictwo',
      range: 'Zakres',
      responsible_person: 'Osoba odpowiedzialna',
      roles: 'Role',
      semester: 'Semestr',
      semester_type: 'Typ semestru',
      semester_number: 'Numer semestru',
      shortcut: 'Skrót',
      shortcut_subject_effect: 'Skrót EP',
      specialization: 'Specjalizacja',
      specialization_name: 'Specjalizacja',
      speciality: 'Specjalność',
      speciality_name: 'Specjalność',
      status: 'Status',
      study_plan_name: 'Nazwa toku',
      study_profile: 'Profil',
      study_profile_name: 'Profil',
      subject: 'Przedmiot',
      subjects: 'Przedmioty',
      subject_form: 'Forma przedmiotu',
      syllabus_card: 'Karta/Sylabus',
      symbol: 'Symbol',
      term: 'Semestr',
      title: 'Tytuł',
      type: 'Poziom',
      type_name: 'Poziom',
      type_of_term: 'Typ semestru',
      valid_date: 'Data obowiązująca',
      year: 'Rok',
      year_and_term: 'Rok i semestr studiów',
    },
    tooltip: {
      heading: 'Domyślna nazwa: ',
      hide: 'Ukryj sekcję',
      info: 'Zgodnie z ustaleniami na wydziale',
      options: 'Opcje',
      remove: 'Usuń sekcję',
    },
    workload: {
      contact_hours: 'Godziny kontaktowe wynikające z harmonogramu studiów',
      ects: 'SUMARYCZNA LICZBA PUNKTÓW ECTS',
      header_1: 'Godziny',
      header_2: 'Średnia liczba godzin na zrealizowanie aktywności',
      no_contact_hours: 'Godziny niekontaktowe - praca własna studenta (przygotowanie do zajęć, egzaminu, itp)',
      other: 'Inne z udziałem nauczyciela (udział w konsultacjach, egzaminie)',
      sum: 'SUMA GODZIN',
    },
  },
  prk_header_navbar: {
    login_btn: 'Zaloguj się',
    register_btn: 'Zarejestruj się',
  },
  prk_navigation_menu: {
    components: 'Komponenty',
    static_pages: 'Strony statyczne',
  },
  pcg_header_messages: {
    empty_messages: 'Nie masz żadnych wiadomości',
    footer_see_more: 'Zobacz więcej >',
    header_title: 'Wiadomości',
  },
  pcg_header_notifications: {
    company_message: '{userName} czeka na akceptację firmy',
    company_title: 'Nowy pracodawca',
    dictionary_message: 'Został dodany nowy słownik - {title}',
    dictionary_title: 'Nowy słownik',
    dictionary_word_title: 'Nowa propozycja słowa',
    dictionary_word_message: 'Użytkownik {userName} zaproponował nowe słowo {title}',
    empty_notifications: 'Nie masz żadnych powiadomień',
    event_message: '{userName} stworzył nowe wydarzenie {title}.',
    event_title: 'Nowe wydarzenie',
    header_title: 'Powiadomienia',
    offer_application_title: 'Aplikacja na ofertę',
    offer_application_message: '{userName} złożył aplikację na ofertę {title}.',
    offer_invitation_title: 'Zaproszenie na ofertę',
    offer_invitation_message: '{userName} zaprasza Cię do aplikacji na ofertę {title}.',
    offer_message: '{userName} stworzył nową ofertę na stanowisko {title}.',
    offer_title: 'Nowa oferta',
  },
  pcg_header_profile_button: {
    lang_change_to: 'Zmień język na {lang}',
    logout: 'Wyloguj mnie',
    role_change_to: 'Zmień rolę na {role}',
    settings: 'Ustawienia',
  },
  pcg_pagination_summary: {
    summary: 'Pokazuje {from} - {to} z {total} wyników',
  },
  pcg_search_select: {
    add_value: 'Dodaj wartość',
    change: 'Zmień',
  },
  prk_modals: {
    add_course: {
      name: 'Dodaj kierunek',
      create_course: 'Stwórz kierunek',
      // TODO powtórzenie w add_matrix
      form: 'Forma (Opcjonalne)',
      level: 'Poziom (Opcjonalne)',
      year: 'Rok akademicki (Opcjonalne)',
      class: 'Rocznik',
    },
    add_discipline: {
      course: 'Tok studiów',
      title: 'Dodaj dyscyplinę',
      valid_date: 'Data obowiązująca',
    },
    add_effect: {
      add_characteristic: 'Dodaj efekt charakterystyki',
      add_teacher: 'Dodaj efekt nauczycielski',
      category: 'Kategoria',
      characteristic: 'Nowy efekt charakterystyki',
      description: 'Opis Efektu',
      new_characteristic_effect: '+ Nowy efekt',
      new_characteristic: 'Nowa charakterystyka',
      new_characteristic_plus: '+ Nowa charakterystyka',
      range: 'Zakres',
      symbol: 'Symbol',
      teacher: 'Nowy efekt nauczycielski',
    },
    add_matrix: {
      add: 'stwórz nową matrycę',
      confirm: 'Czy stworzyć nową matrycę?',
      create_matrix: 'Stwórz matrycę',
      form: 'Forma',
      level: 'Poziom',
      year: 'Rok akademicki',
      class: 'Rocznik',
      specialty: 'Specjalność (Opcjonalne)',
    },
    add_subject: {
      edit: 'Edytuj przedmiot',
      forms_of_classes: 'Formy zajęć',
      form_of_credit: 'Forma zaliczenia',
      name: 'Dodaj nowy przemiot',
      new_form_of_classes: 'Nowa forma zajęć',
    },
    add_standard: {
      year: 'Rok akademicki',
    },
    colors: {
      color: 'Kolor',
      name: 'Nazwa',
      shortcut: 'Skrót',
      level: 'Poziom',
      profile: 'Profil',
      select_color: 'Wybierz kolor',
      select: 'Wybierz',
      add_characteristic: 'Dodaj charakterystkę',
    },
    confirm_copying_syllabus: {
      text: 'Sylabus zostanie nadpisany. Czy na pewno chcesz skopiować?',
    },
    confirm_deletion_syllabuses: {
      text: 'Następujące sylabusy zostały wypełnione. Czy na pewno chcesz je usunąć?',
    },
    confirm_plan_updates: {
      text: 'Następujące przedmioty zostały przypisane do matrycy. Ich nazwy nie zostaną' +
        ' zaktualizowane. Czy na pewno chcesz kontynuować?',
    },
    comments: {
      name: 'Komentarze',
      add_comment: 'Dodaj komentarz',
    },
    coordinators: {
      coordinating_person: 'Osoba koordynująca',
      name: 'Koordynatorzy',
      save: 'Zapisz',
      supervisor: 'Osoba nadzorująca',
    },
    courses_effect: {
      title: 'Efekty standardowe',
    },
    directional: {
      add_field: 'Dodaj dziedzinę',
      new_field: 'Nowa dziedzina',
      add_discipline: 'Dodaj dyscyplinę',
    },
    course_effect: {
      must_select_category: 'Musisz wybrać kategorię',
      must_select_effect: 'Musisz wybrać co najmniej jeden efekt',
      name: 'Dodaj efekt kierunkowy',
      reference_effects: 'Odniesienie do charakterystyk ogólnych',
      save: 'Zatwierdź i dodaj efekt',
      symbol: 'Symbol efektu kierunkowego',
    },
    course_effects: {
      name: 'Dodaj efekty kierunkowe',
      raw_text: 'Efekty kierunkowe',
      select_all: 'Zaznacz efekty kierunkowe, które chcesz dodać',
    },
    effects_list: {
      close: 'Zamknij',
    },
    effects_picker: {
      btn_title: 'Wybierz efekty',
      description: 'Wybierz z którego roku chcesz pobrać efekty',
      name: 'Pobierz efekty z poprzednich lat',
      select_description: 'Rok akademicki',
    },
    file_type: {
      title: 'Wybierz typ pliku',
      warning: 'Musisz wybrać typ pliku',
    },
    plans: {
      import_all: 'Importuj wszystko',
      name: 'Importuj plan',
      save: 'Importuj',
    },
    send_email: {
      applies: 'Dotyczy sylabusa | Dotyczy sylabusów',
      cancel: 'Anuluj',
      email_content: 'Treść emaila',
      message_error: 'Treść jest wymagana',
      name: 'Wyślij email',
      recipients: 'Odbiorcy',
      recipient_error: 'Odbiorca jest wymagany',
      send: 'Wyślij',
      title: 'Tytuł',
      title_error: 'Tytuł jest wymagany',
    },
    standard_picker: {
      description: 'Wybierz standard, który chcesz przypisać do tego kierunku',
      name: 'Pobierz standard',
      name_of_standard: 'Nazwa standardu',
    },
    subject_effect: {
      category: 'Kategoria',
      confirm: 'Zatwierdź i dodaj efekt',
      description: 'Opis efektu przedmiotowego',
      tf: 'Odniesienie do Efektów Nauczycielskich',
      dlo: 'Odniesienie do efektów uczenia się standard/kierunkowe',
      name: 'Dodaj efekt przedmiotowy',
      select_dlo: 'Wybierz Kierunkowe Efekty Uczenia',
      select_tf: 'Wybierz Efekty Nauczycielskie',
    },
    update_plans: {
      confirm: 'Zaktualizować plany',
    },
  },
  view_missing_effects_modal: {
    button: 'Zamknij',
    description: 'Opis',
    symbol: 'Symbol',
    title: 'Nieprzypisane efekty kierunkowe',
  },
}
