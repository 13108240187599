<template>
  <div class="mb-5 prk-shadow br-15">
    <syllabuses-list-filters @update:filters="updateFilters"/>
    <syllabuses-table :filters="filters" @selected="selectedSyllabuses = $event"/>
    <prk-coordinators-modal
        v-if="showCoordinatorsModal"
        :key="rerenderCoordinatorsModal"
        :syllabus-ids="syllabusIds"
        modal-id="coordinators-group"
        @hide="rerenderCoordinatorsModal += 1"
    />
    <statuses-modal :syllabuses="selectedSyllabuses"/>
    <send-email-modal :syllabuses="selectedSyllabuses" />
    <course-effects-modal :syllabus-id="syllabusId" />
  </div>
</template>

<script>
import SyllabusesListFilters from '../../components/syllabus/syllabuses_list/SyllabusesListFilters'
import PrkCoordinatorsModal from '../../components/syllabus/modals/PrkCoordinatorsModal'
import SyllabusesTable from './SyllabusesTable'
import StatusesModal from '../../components/syllabus/modals/StatusesModal'
import SendEmailModal from '../../components/syllabus/modals/PrkSendEmailModal'
import CourseEffectsModal from '../../components/matrices/modals/CourseEffectsModal'
import useFilters from '../../composables/useFilters'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'SyllabusesList',
  setup (_, { root }) {
    const { filters, updateFilters } = useFilters(root)
    return { filters, updateFilters }
  },
  components: {
    SendEmailModal,
    StatusesModal,
    SyllabusesTable,
    PrkCoordinatorsModal,
    SyllabusesListFilters,
    CourseEffectsModal,
  },
  data () {
    return {
      selectedSyllabuses: [],
      rerenderCoordinatorsModal: 0,
    }
  },
  computed: {
    ...mapState('syllabus', ['syllabusId']),
    ...mapGetters('auth', ['currentRole']),
    showCoordinatorsModal () {
      return ['admin', 'employer'].includes(this.currentRole)
    },
    syllabusIds () {
      return this.selectedSyllabuses.map(syllabus => syllabus.id)
    },
  },
  methods: {
    ...mapActions('syllabus', ['setSyllabusId']),
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.syllabus.list'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  destroyed () {
    this.setSyllabusId('')
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .pcg-table-container {
    border-radius: 0 0 15px 15px;

    .pcg-table-content {
      border-radius: 0 0 15px 15px;
    }
  }

  .box {
    border-radius: 15px 15px 0 0 !important;
    padding: 15px 30px 25px 30px !important;
  }
}
</style>
