<template>
  <pcg-btn
      v-if="currentRole !== 'student'"
      round
      :icon="iconRemove"
      text="delete_syllabus"
      variant="danger"
      class="prk-button"
      @click="removeSyllabus"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import useIcon from '../../../composables/useIcon'

export default {
  name: 'SyllabusTableRemoveSyllabus',
  setup (_, { root }) {
    const { iconRemove } = useIcon(root)
    return { iconRemove }
  },
  props: {
    matrix: { type: Object, default: () => null },
    selectedSyllabuses: { type: Array, default: () => ([]) },
    deleteSelected: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('page', ['contrast']),
    selectedSyllabusIds () {
      return this.selectedSyllabuses.map(syllabus => syllabus.id)
    },
    syllabusIds () {
      return this.matrix.syllabuses.map(syllabus => syllabus.id)
    },
  },
  methods: {
    removeSyllabus () {
      try {
        const ids = this.deleteSelected ? this.selectedSyllabusIds : this.syllabusIds
        for (const idsChunk of this.$_.chunk(ids, 100)) {
          const removeHandler = async () => {
            await this.$api.destroy('syllabuses/destroy_all', {
              params: { ids: idsChunk },
            })
          }
          this.$emit('remove:syllabuses', removeHandler)
        }
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },
  },
  watch: {
    contrast () {
      const { iconRemove } = useIcon(this.$root)
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
