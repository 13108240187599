<template>
  <fragment>
    <template v-for="(button, index) in buttonsByResource">
      <base-button :key="index" class="mr-3" :text="button.text"
                   @click="button.action"
      >
        <base-icon :width="button.width" :height="button.height">
          <component :is="`icon-${button.type}`"/>
        </base-icon>
      </base-button>
    </template>
  </fragment>
</template>

<script>
import api from '../api'

export default {
  name: 'PrkGroupPrints',
  props: {
    ids: { required: true, type: Array },
    resource: { required: true, type: String },
    resources: { type: Array, default: () => ([]) },
  },
  data () {
    return {
      buttons: [
        {
          text: 'download_doc',
          type: 'doc',
          width: '20',
          height: '21',
          action: () => {
            if (this.resource === 'subjects') {
              for (const studyPlan of this.selectedStudyPlans) {
                api.post(`${this.resource}/download_doc`, { ids: this.semesterIds(studyPlan) },
                  { responseType: 'blob' }).then((response) => {
                  this.$downloadFile(response, 'docx', this.resourceName(studyPlan.id))
                })
              }
            } else {
              for (const id of this.ids) {
                api.post(`${this.resource}/download_doc`, { id },
                  { responseType: 'blob' }).then((response) => {
                  this.$downloadFile(response, 'docx', this.resourceName(id))
                })
              }
            }
          },
        },
        {
          text: 'download_pdf',
          type: 'pdf',
          width: '18',
          height: '21',
          action: () => this.downloadPdf(),
        },
        {
          text: 'download_pdf',
          type: 'printer',
          width: '41',
          height: '42',
          action: () => this.downloadPdf(),
        },
        //  TODO tymczasowo wyłączone
        // {
        //   text: 'download_excel',
        //   type: 'excel',
        //   width: '21',
        //   height: '21',
        //   action: () => {
        //     api.post(`${this.resource}/download_csv`, { ids: this.ids },
        //       { responseType: 'blob' }).then((response) => {
        //       this.$downloadFile(response, 'csv', this.$tc('general.syllabuses', 2))
        //     })
        //   },
        // },
      ],
    }
  },
  computed: {
    selectedStudyPlans () {
      if (this.resource === 'syllabuses') return []
      return this.resources.filter(studyPlan => {
        return studyPlan.semesters.map(sem => sem.id).some(id => this.ids.includes(id))
      })
    },
    buttonsByResource () {
      if (this.resource === 'syllabuses') {
        return this.buttons
      } else {
        return this.buttons.slice(0, this.buttons.length - 1)
      }
    },
  },
  methods: {
    resourceName (id) {
      return this.resources.find(resource => resource.id === id).name
    },
    semesterIds (studyPlan) {
      const semesterIds = studyPlan.semesters.map(sem => sem.id)
      return semesterIds.filter(semId => this.ids.includes(semId))
    },
    downloadPdf () {
      if (this.resource === 'subjects') {
        for (const studyPlan of this.selectedStudyPlans) {
          api.post(`${this.resource}/download_pdf`, { ids: this.semesterIds(studyPlan) },
            { responseType: 'blob' }).then((response) => {
            this.$downloadFile(response, 'pdf', this.resourceName(studyPlan.id))
          })
        }
      } else {
        for (const id of this.ids) {
          api.post(`${this.resource}/download_pdf`, { id },
            { responseType: 'blob' }).then((response) => {
            this.$downloadFile(response, 'pdf', this.resourceName(id))
          })
        }
      }
    },
  },
}
</script>
