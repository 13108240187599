<template>
  <div class="effects">
    <objective-effects
        :categories="categories"
        :effects="preview ? dumbEffects : subjectEffects"
        :remove-effect="removeEffectById"
        :edit-effect="editEffect"
        :actions="status !== 'accepted' && editMode"
        :section="section"
        show-headline
    />
    <div v-if="fillContent && editMode" class="d-flex justify-content-center add-effect">
      <pcg-btn v-b-modal.effects-modal>
        <span class="prk-fz-12">
          + {{ $t('components.prk_syllabus.buttons.new_subject_effect') }}
        </span>
      </pcg-btn>

      <subject-effect-modal :categories="categories"/>
    </div>
  </div>
</template>

<script>
import ObjectiveEffects from '../../ObjectiveEffects'
import { inject } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import SubjectEffectModal from '../../modals/PrkSubjectEffectModal'
import { fillStatus } from '../../../../mixins/syllabus/status'

export default {
  name: 'SyllabusObjectiveEffects',
  setup () {
    const status = inject('status')
    const categories = inject('categories', [])
    const preview = inject('preview', false)
    const dumbEffects = inject('learningEffects', [])
    const dumbCategories = inject('categories', [])
    const editMode = inject('editMode', false)
    return {
      editMode,
      dumbCategories,
      dumbEffects,
      preview,
      status,
      categories,
    }
  },
  components: {
    SubjectEffectModal,
    ObjectiveEffects,
  },
  props: {
    section: Object,
  },
  mixins: [fillStatus],
  computed: {
    ...mapGetters('syllabusFill', ['getSubjectEffects']),
    subjectEffects () {
      return this.getSubjectEffects.map(effect => {
        return this.$_.omit(effect, 'formOfClass', 'formOfCredits', 'categoryName')
      })
    },
  },
  methods: {
    ...mapActions('courseLearningOutcome', ['setKeu', 'setEditMode']),
    async removeEffectById (effect) {
      const response = await this.$removeSwal()
      if (response.value) {
        await this.$store.dispatch('syllabusFill/removeSubjectEffect', effect.id)
      }
    },

    editEffect (effect) {
      const keu = this.$_.cloneDeep(this.getSubjectEffects.find(eff => eff.id === effect.id))
      this.setKeu(keu)
      this.setEditMode(true)
      this.$bvModal.show('effects-modal')
    },
  },
}
</script>
<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.effects {
  background-color: $pcg-white;
}

::v-deep {
  .add-effect {
    .pcg-button {
      max-height: 30px;
      line-height: 1.86rem;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .effects {
    background-color: $pcg-white;
  }
}
</style>
