<template>
  <div class="pb-5">
    <div class="prk-shadow br-15 matrices-list">
      <prk-list-filter
          filters-type="matrix"
          @update:filters="updateFilters"
      />
      <matrices-table :filters="filters" @copy:matrix="copiedMatrix = $event"/>
      <prk-new-matrix-modal
          :key="newMatrixModalKey"
          :edited-matrix="copiedMatrix"
          :current-year="filters.academic_year"
          @hidden="hideNewMatrixModal"
      />
    </div>
  </div>
</template>

<script>
import MatricesTable from '../../components/matrices/MatricesTable'
import PrkListFilter from '../../components/PrkListFilter'
import PrkNewMatrixModal from '../../components/matrices/modals/PrkNewMatrixModal'
import useFilters from '../../composables/useFilters'

export default {
  name: 'MatricesList',
  setup (_, { root }) {
    const { filters, updateFilters } = useFilters(root)
    return { filters, updateFilters }
  },
  components: {
    PrkNewMatrixModal,
    PrkListFilter,
    MatricesTable,
  },
  data () {
    return {
      newMatrixModalKey: 0,
      copiedMatrix: null,
    }
  },
  methods: {
    hideNewMatrixModal () {
      this.copiedMatrix = null
      this.newMatrixModalKey += 1
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.matrices'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 0 0 15px 15px;

    .pcg-table-content {
      border-radius: 0 0 15px 15px;
    }
  }
}
</style>
