<template>
  <b-row class="mt-3">
    <b-col lg="6" md="12">
      <div class="prk-pcg-fz-12 pcg-the-darkest-gray-color">
        {{ $t('components.prk_modals.subject_effect.dlo') }}
      </div>
      <prk-tags :value="keks" @removed="removeKek"/>
      <pcg-btn
          variant="additional"
          @click="clickAddEffect">
        {{ $t('components.prk_modals.subject_effect.select_dlo') }}
      </pcg-btn>
    </b-col>
    <b-col lg="6" md="12" class="mt-3 mt-lg-0">
      <div class="prk-pcg-fz-12 pcg-the-darkest-gray-color">
        {{ $t('components.prk_modals.subject_effect.tf') }}
      </div>
      <prk-tags :value="kens" @removed="removeKen"/>
      <pcg-btn variant="additional" @click="clickAddTeachingEffects">
        {{ $t('components.prk_modals.subject_effect.select_tf') }}
      </pcg-btn>
    </b-col>
  </b-row>
</template>
<script>
import PrkTags from '../../PrkTags'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'syllabus-effects',
  components: { PrkTags },
  props: {
    clickAddEffect: Function,
  },
  methods: {
    ...mapActions('courseLearningOutcome', ['removeKek', 'removeKen']),
    clickAddTeachingEffects () {
      if (this.hasTeachingEffects) {
        this.$bvModal.show('teachingEffectModal')
      } else {
        this.$toastr.w(this.$t('general.teaching_effects_off'))
      }
    },
  },
  computed: {
    ...mapState('syllabusFill', ['hasTeachingEffects']),
    ...mapState('courseLearningOutcome', ['keu']),
    keks () {
      return this.keu.keks.map(kek => ({ text: kek.symbol, value: kek.id }))
    },
    kens () {
      return this.keu.kens.map(kek => ({ text: kek.symbol, value: kek.id }))
    },
  },
}
</script>
