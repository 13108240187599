<template>
  <pcg-modal
      :modal-id="modalId"
      size="lg"
      :title="$t('components.prk_modals.send_email.name')"
      icon="pcg-icon-document"
      :btn-title="$t('components.prk_modals.send_email.send')"
      :action="sendEmail"
      @show="show"
      @change="changeNumberOfRows"
      @hide="hide"
  >

    <b-row class="pt-0 scroll">
      <b-col class="pcg-the-darkest-gray-color ">
        <span class="d-block font-weight-bolder">
          {{ $tc('components.prk_modals.send_email.applies', syllabuses.length) }}
        </span>
        <span v-if="!syllabuses.length" class="prk-fz-12">{{ syllabus.name }}</span>
        <template v-else>
          <span v-for="(syllabusName, index) in syllabusNames" :key="index"
                class="d-block prk-fz-12">
            {{ syllabusName }}
          </span>
        </template>
      </b-col>
    </b-row>

    <b-row class="pt-2 scroll">
      <b-col cols="auto">
        <span class="d-block font-weight-bolder">
          {{ $t('components.prk_modals.send_email.recipients') }}
        </span>
        <div>
          <span v-for="(educator, index) in $_.uniqBy(educators, 'text')" :key="educator.id"
                class="prk-fz-12"
          >
            {{ educator.text }}<span v-if="index !== $_.uniqBy(educators, 'text').length - 1">, </span>
          </span>
        </div>
      </b-col>
    </b-row>

    <b-row class="pt-4">
      <b-col>
        <pcg-text-input
            v-model="title"
            :label="$t('components.prk_modals.send_email.title')"
            :show-error="$v.title.$error"
            :error-text="$t('components.prk_modals.send_email.title_error')"
        >
        </pcg-text-input>
      </b-col>
    </b-row>

    <b-row class="pt-2">
      <b-col>
        <label class="pcg-input-label">{{ $t('components.prk_modals.send_email.email_content') }}</label>
        <textarea-autosize
            ref="textarea"
            v-model="message"
            type="textarea"
            :min-height="30"
            class="text-area-autosize form-control pcg-field"
        />
        <div v-if="$v.message.$error" class="pcg-error-messages">{{ $t('components.prk_modals.send_email.message_error') }}</div>
      </b-col>
    </b-row>

    <template #button-before>
      <pcg-btn variant="additional" @click="hide">
        {{ $t('components.prk_modals.send_email.cancel') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'prk-send-email-modal',
  props: {
    syllabus: { type: Object, default: () => ({}) },
    syllabuses: { type: Array, default: () => ([]) },
    modalId: { type: String, default: 'send-email-modal' },
  },
  validations: {
    title: { required },
    message: { required },
  },
  data () {
    return {
      message: '',
      title: '',
      educators: [],
    }
  },
  computed: {
    educatorIds () {
      return this.educators.map(educator => educator.value)
    },
    syllabusNames () {
      return this.syllabuses.map(educator => educator.name)
    },
  },
  methods: {
    changeNumberOfRows () {
      this.$refs.textarea.$el
        .querySelector('textarea')
        .setAttribute('rows', '8')
    },
    async sendEmail () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        await this.$api.post('educators/send_email/', {
          ids: this.educatorIds,
          message: this.message,
          title: this.title,
        })
        this.clear()
        this.$bvModal.hide(`send-email-modal-${this.syllabus.id}`)
        this.$bvModal.hide('send-email-modal')
        this.$toastr.s(this.$t('views.syllabus_list.email.success'))
      } catch (e) {
        this.$showError(e.errors)
      }
    },
    clear () {
      this.message = ''
      this.title = ''
    },
    async show () {
      const params = this.$_.pickBy({
        syllabus_id: this.syllabus.id,
        syllabus_ids: this.syllabuses.map(syllabus => syllabus.id),
      }, this.$_.identity)

      const { data: educators } = await this.$api.get('educators', {
        ...params,
      })
      this.educators = educators.map(educator => {
        return {
          text: `${educator.attributes.firstName} ${educator.attributes.lastName}`,
          value: educator.id,
          email: educator.attributes.email,
        }
      })
    },
    hide () {
      this.$bvModal.hide(`send-email-modal-${this.syllabus.id}`)
      this.$bvModal.hide('send-email-modal')
      this.$v.$reset()
      this.clear()
    },
  },
}
</script>

<style scoped>
.scroll {
  overflow-y: auto;
  max-height: 150px;
}
</style>
