<template>
  <div class="prk-pcg-box-card px-5 py-4">
    <prk-table-header
        v-bind="$attrs"
        :show-headline-index="false"
        @update:header="$emit('update:header', $event)"
        @update:visible="$emit('update:visible', $event)"
        @update:showOnThePrintout="$emit('update:showOnThePrintout', $event)"
        @add-description="addDescription"
        @close-section="closeSection"
    />
    <template v-if="$attrs.visible">
      <template v-if="$attrs.workload">
        <div class="border-top-section mt-0"/>
        <prk-workload-element/>
      </template>
      <template v-if="$attrs.literature">
        <div class="border-top-section mt-0"/>
        <prk-literature-element/>
      </template>

      <template v-if="$attrs.automaticDownload">
        <div class="border-top-section"/>
        <prk-type-completion automatic-download/>
      </template>

      <template v-if="$attrs.fillByTeacher">
        <div class="border-top-section"/>
        <prk-type-completion :automatic-download="false"/>
      </template>

      <template>
        <prk-section-new-element
            v-for="(_, index) in visibleElements" :key="index"
            v-bind.sync="visibleElements[index]"
            @close="closeElement(index)"
        />
      </template>

      <template>
        <div class="border-top-section"/>
        <prk-choose-element
            @add-checkbox="addElement('checkbox')"
            @add-one-line="addElement('one_line')"
            @add-text-field="addElement('text_field')"
            @add-table="addElement('table')"
        />

      </template>
      <prk-description v-if="visibleDescription"
                       v-bind:description.sync="description"
      />
    </template>
  </div>
</template>

<script>
import PrkTableHeader from '../PrkTableHeader'
import PrkChooseElement from './elements/PrkChooseElement'
import PrkSectionNewElement from './elements/PrkSectionNewElement'
import PrkWorkloadElement from './elements/PrkPcgWorkloadElement'
import PrkLiteratureElement from './elements/PrkLiteratureElement'
import PrkTypeCompletion from './elements/PrkTypeCompletion'
import PrkDescription from './elements/PrkDescription'
import useSyllabusTemplates from '../../../composables/useSyllabusTemplates'

export default {
  name: 'PrkBasicSection',
  setup (_, { root }) {
    const { setDefaultDataForSubSection } = useSyllabusTemplates(root)
    return { setDefaultDataForSubSection }
  },
  components: {
    PrkTypeCompletion,
    PrkDescription,
    PrkLiteratureElement,
    PrkWorkloadElement,
    PrkSectionNewElement,
    PrkChooseElement,
    PrkTableHeader,
  },
  data () {
    return {
      visibleDescription: false,
      description: '',
    }
  },
  computed: {
    visibleElements () {
      if (!this.$attrs.elements?.length) return []
      const filtered = Object.entries(this.$attrs.elements).filter(el => el[1].visible)
      return Object.fromEntries(filtered)
    },
  },
  watch: {
    '$attrs.description': {
      immediate: true,
      handler (newVal) {
        this.description = newVal
      },
    },
    description () {
      this.$emit('update:description', this.description)
    },
  },
  methods: {
    addDescription (state) {
      this.visibleDescription = state
    },

    addElement (elementType) {
      const data = this.setDefaultDataForSubSection(elementType)
      const elements = this.$_.clone(this.$attrs.elements)
      elements.push({
        elementType,
        descriptionAbove: '',
        descriptionUnder: '',
        label: '',
        visible: true,
        value: data,
      })
      this.$emit('update:elements', elements)
    },

    closeElement (index) {
      const elements = this.$_.clone(this.$attrs.elements)
      elements.splice(index, 1)
      this.$emit('update:elements', elements)
    },

    closeSection () {
      this.$emit('close-section', this.$attrs.number)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';
@import 'app/javascript/prk/assets/stylesheets/custom';

::v-deep {
  .pcg-textarea {
    height: 54px;
  }
}

.border-top-section {
  margin: 2rem -2.2rem 0;
  border-top: 1px solid $pcg-lightest-gray;
  padding-top: 1.285rem;
}

.prk-pcg-box-card {
  border-radius: 5px;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .border-top-section {
    border-top: 1px solid $pcg-lightest-gray;
  }
}
</style>
