<template>
  <div class="prk-pcg-box-card ">
    <prk-table-header
        class="px-5 py-4"
        v-bind="$attrs"
        :show-headline-index="false"
        @update:header="$emit('update:header', $event)"
        @update:visible="$emit('update:visible', $event)"
        @update:showOnThePrintout="$emit('update:showOnThePrintout', $event)"
        @add-description="addDescription"
    />

    <div v-if="$attrs.visible">
      <prk-table
          :items="$attrs.rows"
          :show-headline="false"
          :excluded-fields="excludedFields"
          :fields-props="{ thClass: 'd-none' }"
          disable-actions
      >
        <template v-slot:content="{ header, data }">
          <div v-if="header.key === 'information'"
               class="position-relative font-weight-bold">
            {{ $t('components.prk_syllabus.basic_information.' + data.item.information) }}
            <img v-if="data.item.questionMark"
                 v-b-popover.hover.top="$t('components.prk_syllabus.tooltip.info')"
                 :src="require('../../../assets/images/icons/question.svg')"
                 class="info-icon info"
            />
          </div>
          <div v-else-if="header.key === 'fill'" class="d-flex align-content-center ml-3 ml-lg-0">
            <div v-if="data.item.fill === 'refill'"
                 class="prk-pcg-syllabus-type">
              <prk-type-completion :automatic-download="false"/>
            </div>
            <div v-else-if="data.item.fill === 'automatic'"
                 class="prk-pcg-syllabus-type">
              <prk-type-completion :automatic-download="true"/>
            </div>
          </div>
          <div v-else-if="header.key === 'visible'"
               class="d-flex justify-content-end">
            <pcg-switch
                v-b-popover.hover.top="$t('components.prk_syllabus.tooltip.hide')"
                :value="data.item.visible"
                @input="updateRowVisible($event, data.index)"
            />
          </div>
        </template>
      </prk-table>
      <prk-description v-bind:description.sync="description" v-if="visibleDescription"/>
    </div>
    <template v-if="$attrs.visible">
      <syllabus-box>
        <template #content>
          <template>
            <div class="border-top-section"/>
            <prk-section-new-element
                v-for="(_, index) in $attrs.elements" :key="index"
                v-bind.sync="$attrs.elements[index]"
                @close="closeElement(index)"
            />
          </template>
          <div class="border-top-section"/>
          <prk-choose-element
              @add-checkbox="addElement('checkbox')"
              @add-one-line="addElement('one_line')"
              @add-text-field="addElement('text_field')"
              @add-table="addElement('table')"
          />
        </template>
      </syllabus-box>
    </template>
  </div>
</template>

<script>
import PrkTableHeader from '../PrkTableHeader'
import PrkDescription from './elements/PrkDescription'
import PrkTable from '../../PrkTable'
import PrkTypeCompletion from './elements/PrkTypeCompletion'
import PrkChooseElement from './elements/PrkChooseElement'
import SyllabusBox from '../PrkSyllabusBox'
import PrkSectionNewElement from './elements/PrkSectionNewElement'
import useSyllabusTemplates from '../../../composables/useSyllabusTemplates'

export default {
  name: 'PrkBasicInformation',
  setup (_, { root }) {
    const { setDefaultDataForSubSection } = useSyllabusTemplates(root)
    return { setDefaultDataForSubSection }
  },
  components: {
    PrkSectionNewElement,
    SyllabusBox,
    PrkChooseElement,
    PrkTypeCompletion,
    PrkTable,
    PrkDescription,
    PrkTableHeader,
  },
  data () {
    return {
      excludedFields: ['questionMark', 'elementType', 'value', 'selected', 'whence', 'additional',
        'position', 'id', 'comments', 'commentCount'],
      description: '',
      visibleDescription: false,
      refSection: this.section,
    }
  },
  methods: {
    addElement (elementType) {
      const data = this.setDefaultDataForSubSection(elementType)
      const elements = this.$_.clone(this.$attrs.elements)
      elements.push({
        elementType,
        descriptionAbove: '',
        descriptionUnder: '',
        label: '',
        visible: true,
        value: data,
      })
      this.$emit('update:elements', elements)
    },
    closeElement (index) {
      const elements = this.$_.clone(this.$attrs.elements)
      elements.splice(index, 1)
      this.$emit('update:elements', elements)
    },

    addDescription (state) {
      this.visibleDescription = state
    },
    restore () {
      this.refSection.header = this.$t(`components.prk_syllabus.sections.number_${
          this.refSection.number.replace('.', '_')}`)
    },
    updateRowVisible (event, index) {
      const clonedRows = this.$_.cloneDeep(this.$attrs.rows)
      clonedRows[index].visible = event
      this.$emit('update:rows', clonedRows)
    },
  },
  computed: {
    visibleElements () {
      if (!this.$attrs.elements?.length) return []
      const filtered = Object.entries(this.$attrs.elements).filter(el => el[1].visible)
      return Object.fromEntries(filtered)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';
@import 'app/javascript/prk/assets/stylesheets/custom';

::v-deep {

  .additional {
    .pcg-table-narrow-row {
      border-top: 1px solid $pcg-lightest-gray;
    }
  }

  td {
    width: 40%;
  }

  .switch {
    width: 32px;
  }

  .description-container {
    margin-bottom: 1rem;
    padding: 0 2.14rem;
  }

  .pcg-input-label {
    font-weight: normal;
  }
}

.prk-pcg-box-cart-padding {
  padding-bottom: 0;
}

.prk-pcg-box-card {
  padding-bottom: 0 !important;
}

.prk-pcg-syllabus-type {
  padding: 5px 0;
  font-style: italic;
  font-size: 0.857rem; // 12px
  line-height: 1.571rem; // 22px
}

.prk-pcg-box-card {
  padding-bottom: 1rem;
}

img {
  margin-left: 1.4rem;
  margin-right: .4rem;

  &.info {
    cursor: pointer;
    position: absolute;
    top: - .7rem;
  }
}

.disable {
  background-color: $pcg_lightest_gray;
}

::v-deep {
  .pcg-table-container {
    border-radius: 0 0 15px 15px;

    .pcg-table-content {
      border-radius: 15px;
    }
  }
}

::v-deep {
  .pcg-textarea {
    height: 54px;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {

    .additional {
      .pcg-table-narrow-row {
        border-top: 1px solid $pcg-lightest-gray;
      }
    }
  }

  .disable {
    background-color: $pcg-lightest-gray;
  }
}
</style>
