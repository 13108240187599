import { render, staticRenderFns } from "./SyllabusObjectiveEffects.vue?vue&type=template&id=ad9ccd36&scoped=true&"
import script from "./SyllabusObjectiveEffects.vue?vue&type=script&lang=js&"
export * from "./SyllabusObjectiveEffects.vue?vue&type=script&lang=js&"
import style0 from "./SyllabusObjectiveEffects.vue?vue&type=style&index=0&id=ad9ccd36&scoped=true&lang=scss&"
import style1 from "./SyllabusObjectiveEffects.vue?vue&type=style&index=1&id=ad9ccd36&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad9ccd36",
  null
  
)

export default component.exports