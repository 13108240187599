import VueCompositionApi, { ref } from '@vue/composition-api'
import Vue from 'vue'

Vue.use(VueCompositionApi)

export default function (root) {
  const syllabus = ref({
    id: '',
    language: null,
    name: null,
    status: 'template',
    logo: {},
    syllabus_sections: [],
  })

  const sections = ref([
    {
      component: 'subject-information',
      description: '',
      detailedComponent: 'basic-information-about-subject',
      rows: [
        {
          information: 'subject_code',
          fill: 'refill',
          questionMark: true,
          elementType: 'input',
          value: '',
          visible: true,
        },
        {
          information: 'row_2',
          fill: 'automatic',
          elementType: 'text',
          value: 'Zarządzanie',
          visible: true,
        },
        {
          information: 'row_3',
          fill: 'refill',
          selected: 0,
          elementType: 'input',
          value: '',
          visible: true,
        },
        {
          information: 'row_4',
          fill: 'refill',
          elementType: 'textarea',
          value: '',
          visible: true,
        },
        {
          information: 'row_5',
          fill: 'refill',
          elementType: 'input',
          value: '',
          visible: true,
        },
        {
          information: 'row_6',
          fill: 'automatic',
          value: 'Katedra ekonomiki i zarządzania',
          elementType: 'text',
          visible: true,
        },
        {
          information: 'row_7',
          fill: 'automatic',
          elementType: 'text',
          value: 'I stopień',
          visible: true,
        },
        {
          information: 'row_8',
          fill: 'automatic',
          elementType: 'text',
          value: 'Ogólnoakademicki',
          visible: true,
        },
        {
          information: 'row_9',
          fill: 'automatic',
          elementType: 'text',
          value: 'stacjonarne',
          visible: true,
        },
        {
          information: 'row_10',
          fill: 'automatic',
          elementType: 'text',
          value: 'American Studies',
          visible: true,
        },
        {
          information: 'row_11',
          fill: 'automatic',
          elementType: 'text',
          value: '2 rok, semestr III/IV',
          visible: true,
        },
        {
          information: 'row_12',
          fill: 'refill',
          elementType: 'input',
          value: '',
          visible: true,
          whence: 'refill',
        },
        {
          information: 'language',
          fill: 'refill',
          elementType: 'dropdown',
          value: 0,
          visible: true,
        },
        {
          information: 'row_14',
          fill: 'automatic',
          elementType: 'text',
          value: 'Prof. Maria Mróz',
          visible: true,
        },
        {
          information: 'leading_persons',
          fill: 'refill',
          elementType: 'autocomplete',
          value: [{
            text: 'dr. hab. Mariola Grzebyk',
            value: 0,
          },
          {
            text: 'prof. Maria Mróz',
            value: 1,
          }],
          visible: true,
        }],
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_1_0'),
      number: '1',
      showOnThePrintout: true,
      visible: true,
      basicInformationId: '',
      additionalElements: [],
    },
    {
      automaticDownload: true,
      description: '',
      component: 'subject-information',
      detailedComponent: 'prk-table',
      header: root.$t('components.prk_syllabus.sections.number_1_1'),
      elements: [],
      link: 'syllabus/forms_of_teaching',
      number: '1.1',
      visible: true,
      showOnThePrintout: true,
    },
    {
      component: 'empty-subsection',
      description: '',
      detailedComponent: '',
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_1_2'),
      number: '1.2',
      showOnThePrintout: true,
      visible: true,
    },
    {
      automaticDownload: true,
      description: '',
      component: 'subject-information',
      detailedComponent: 'prk-table',
      header: root.$t('components.prk_syllabus.sections.number_1_3'),
      elements: [],
      link: 'syllabus/form_of_passing_the_subject',
      number: '1.3',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-section',
      description: '',
      detailedComponent: '',
      header: root.$t('components.prk_syllabus.sections.number_2'),
      elements: [],
      number: '2',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-section',
      description: '',
      detailedComponent: '',
      header: root.$t('components.prk_syllabus.sections.number_3'),
      number: '3',
      elements: [],
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-subsection',
      description: '',
      detailedComponent: '',
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_3_1'),
      number: '3.1',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'education-goals',
      description: '',
      detailedComponent: 'objective-effects',
      fillByTeacher: true,
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_3_2'),
      number: '3.2',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-subsection',
      description: '',
      detailedComponent: '',
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_3_3'),
      number: '3.3',
      showElements: true,
      showOnThePrintout: true,
      visible: true,
      visibleElements: {
        textField: true,
        table: true,
      },
    },
    {
      component: 'education-goals',
      description: '',
      detailedComponent: 'didactic-methods-content',
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_3_4'),
      number: '3.4',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-section',
      description: '',
      detailedComponent: '',
      header: root.$t('components.prk_syllabus.sections.number_4'),
      elements: [],
      number: '4',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'assessment-methods',
      description: '',
      detailedComponent: '',
      header: root.$t('components.prk_syllabus.sections.number_4_1'),
      number: '4.1',
      elements: [],
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-subsection',
      description: '',
      detailedComponent: '',
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_4_2'),
      number: '4.2',
      showOnThePrintout: true,
      visible: true,
      visibleElements: {
        textField: true,
        table: true,
      },
    },
    {
      component: 'student-workload',
      description: '',
      detailedComponent: '',
      header: root.$t('components.prk_syllabus.sections.number_5'),
      number: '5',
      border: true,
      workload: true,
      elements: [],
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'empty-section',
      detailedComponent: '',
      elements: [],
      header: root.$t('components.prk_syllabus.sections.number_6'),
      description: '',
      number: '6',
      showOnThePrintout: true,
      visible: true,
    },
    {
      component: 'literature',
      detailedComponent: '',
      header: root.$t('components.prk_syllabus.sections.number_7'),
      number: '7',
      description: '',
      elements: [],
      literature: true,
      showOnThePrintout: true,
      visible: true,
    },
  ])

  return {
    sections,
    syllabus,
  }
}
