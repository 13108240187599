<template>
  <div>
    <div v-if="courseSpinner" class="text-center text-danger py-3 pcg-white-background-color">
      <pcg-spinner variant="small"/>
    </div>
    <prk-table
        v-else
        :excluded-fields="excludedFields"
        :items="items"
        :show-headline="false"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: colgroup(field.key) }"
        >
      </template>
      <template v-slot:head(id)>
        <pcg-checkbox :value="checkedAll" class="ml-3" @input="select"/>
      </template>
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'id'" class="position-relative">
          <pcg-checkbox v-model="data.item.selected" class="ml-3"/>
          <div class="prk-new-effects-info"
               v-if="data.item.needUpdate"
               v-b-popover.hover.top="$t('components.prk_courses.tooltip')">
            !
          </div>
          <img src="../../assets/images/icons/standard.svg"
               class="standard"
               v-if="data.item.fromStandard"
               v-b-popover.hover.top="$t('components.prk_courses.standard')"
          />

        </div>
        <div v-else-if="header.key === 'actions'" class="d-flex justify-content-end">
          <pcg-btn round text="edit_course" class="mr-3 prk-button" :icon="iconEdit"
                   @click="editCourse(data.item.id, data.item.course)"
          />
          <pcg-btn round :icon="iconRemove" text="delete_course"
                   class="mr-3 prk-button" variant="danger"
                   @click="removeCourse(data.index, data.item.id)"/>
          <prk-icon-options :options="options" @click="courseId = data.item.id "/>
        </div>
      </template>
    </prk-table>

    <portal to="course-pagination">
      <pcg-pagination
          v-if="pagination.totalPages"
          :page-count="pagination.totalPages"
          :total-records="pagination.totalRecords"
          v-model="pagination.currentPage"
          class="mt-4 prk-pagination"
      />
    </portal>
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import PrkIconOptions from '../PrkIconOptions'
import useList from '../../composables/useList'
import useIcon from '../../composables/useIcon'
import useCourses from '../../composables/useCourses'
import { CoursesTablePrintouts } from '../../mixins/courses/courses_table_printouts'
import { mapGetters } from 'vuex'
import usePagination from '../../composables/usePagination'

export default {
  name: 'CoursesTable',
  setup (props, { root }) {
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      checkedAll,
      items,
      setItems,
      select,
      removeRow,
    } = useList(root)
    const { course, getCourse, saveCourse } = useCourses(root)
    const { pagination, setPagination } = usePagination(root)
    return {
      course,
      checkedAll,
      getCourse,
      iconEdit,
      iconRemove,
      items,
      pagination,
      removeRow,
      saveCourse,
      setItems,
      setPagination,
      select,
    }
  },
  components: {
    PrkIconOptions,
    PrkTable,
  },
  mixins: [CoursesTablePrintouts],
  props: {
    filters: { required: true, type: Object },
  },
  data () {
    return {
      courseId: '',
      excludedFields: ['selected', 'needUpdate', 'fromStandard'],
      courseSpinner: false,
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    courseId () {
      this.$emit('update:course-id', this.courseId)
    },
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
    pagination: {
      deep: true,
      handler () {
        this.$emit('update:current-page', this.pagination.currentPage)
      },
    },
    filters: {
      deep: true,
      handler () {
        if (this.filters.page && this.filters.page !== this.pagination.currentPage) {
          this.pagination.currentPage = this.filters.page
        }
      },
    }
  },
  async created () {
    await this.getCourses(`courses?${this.$qs.stringify({
          filters: this.filters,
          page: this.filters.page,
        })}`,
    )
    this.$watch('filters', this.$_.debounce(async function () {
      this.courseSpinner = true
      await this.getCourses(`courses/search?${this.$qs.stringify({
        filters: this.filters,
        page: this.filters.page,
      })}`)
    }, 200), {
      deep: true,
    })
  },
  methods: {
    async getCourses (url) {
      try {
        const response = await this.$api.get(url)
        this.setPagination(response)
        const courses = response.data.map(course => {
          return {
            id: course.id,
            faculty: course.attributes.facultyName,
            course: course.attributes.name,
            speciality: course.attributes.speciality,
            type: course.attributes.type,
            studyProfile: course.attributes.studyProfile,
            fromStandard: course.attributes.fromStandard,
            needUpdate: course.attributes.needUpdate,
            selected: false,
          }
        })
        this.setItems(courses)
        this.courseSpinner = false
      } catch (error) {
        this.$toastr.e(this.$t('general.check_errors'))
        this.courseSpinner = false
      }
    },
    // #TODO powtórzenie w StudyPlansActions
    editCourse (id, courseName) {
      this.$router.push({
        name: 'courses_edit_path',
        params: { id: id },
        query: { ...this.$route.query, course: courseName },
      })
    },
    async removeCourse (index, id) {
      const removeHandler = async () => {
        await this.$api.destroy(`courses/${id}`)
      }
      await this.removeRow(index, null, removeHandler)
    },
    thAttr (value, key) {
      return key === 'id' ? { style: 'width:2em' } : {}
    },
    colgroup (field) {
      switch (field) {
        case 'id':
          return '5%'
        case 'actions':
          return '10%'
        default:
          return ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.prk-new-effects-info {
  width: 1.429rem;
  background-color: $prk-dark-orange;
  color: $pcg-white;
  border-radius: 50%;
  text-align: center;
  line-height: 1.429rem;
  font-size: 1.1rem;
  position: absolute;
  top: -.2rem;
  left: -1.857rem;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
}

.standard {
  position: absolute;
  top: -.2rem;
  left: -1.2rem;
  cursor: pointer;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-new-effects-info {
    background-color: $prk-dark-orange;
    color: $pcg-white;
  }
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
  .prk-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
