<template>
  <div class="d-flex align-items-center">
      <span v-if="header.key === 'information'" class="font-weight-bold">
        {{ $t('components.prk_syllabus.basic_information.' + information) }}
      </span>
    <basic-information-about-subject-value
        :header="header"
        :information="information"
        :value="normalizedValue"
    />
    <div v-if="showQuestionMark"
         v-b-popover.hover.top="courseCodeText"
         class="question ml-3 font-weight-bold">
      ?
    </div>
  </div>
</template>
<script>
import BasicInformationAboutSubjectValue from './BasicInformationAboutSubjectValue'

export default {
  name: 'basic-information-about-subject-text',
  components: { BasicInformationAboutSubjectValue },
  props: {
    header: { required: true, type: Object },
    information: { required: true, type: String },
    value: { type: [Number, String, Array], default: '' },
  },
  computed: {
    showQuestionMark () {
      return this.header.key === 'information' && this.information === 'subject_code'
    },
    courseCodeText () {
      return this.$t('components.prk_syllabus.table.course_code_info')
    },
    normalizedValue () {
      return [null, undefined].includes(this.value) ? '-' : this.value
    },
  },
}
</script>

<style scoped>
.question {
  cursor: pointer;
}
</style>
