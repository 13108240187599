<template>
  <div>
    <div class="d-flex justify-content-end">
      <pcg-btn class="font-weight-bold" size="small"
               variant="additional" v-b-modal.downloadModal>
        {{ $t('components.prk_courses.download') }}
      </pcg-btn>
    </div>
    <div class="matrix-subjects-list">
      <prk-table
          v-if="matrixSubjectNames.length > 0"
          :custom-fields="customFields"
          disable-actions
          class="subject-with-syllabuses"
          :excluded-fields="EXCLUDED_FIELDS"
          :items="matrixSubjectNames"
          :show-headline="false"
          :fields-props="{ tdClass: 'py-4' }"
          :busy="loading"
      >
        <template v-slot:head(customId)>
          <pcg-checkbox :value="selectedAll" @input="selectAll"/>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(customId)="{ item: subject }">
          <pcg-checkbox v-model="subject.selected" @input="select"/>
        </template>

        <template v-slot:content="{ header, data }">
          <div v-if="header.key === 'courseEffects'">
            {{ getKeks(data.index) }}
          </div>

          <div v-else-if="header.key === 'numberOfSyllabuses'">
            <span>{{ data.item[header.key]}}</span>
          </div>

          <div v-else-if="header.key === 'actions'"
               class="pcg-main-active-color">
            <div v-if="data.item.courseEffectsSubItems.length > 0"
                 @click="data.item._showDetails = !data.item._showDetails"
                 class="d-flex justify-content-end align-center">
              <span style="line-height: 1.5rem" class="pointer font-weight-bold"
                    @click="id = data.item.subjectNameId">
                {{ expandInfo(data.item._showDetails) }}
              </span>
              <base-arrow :show="data.detailsShowing" class="prk-fz-20"/>
            </div>

            <div v-else class="d-flex justify-content-end">
              <pcg-btn
                  class="m-0"
                  variant="additional"
                  size="small"
                  v-b-modal.course-effects-modal
                  @click="customId = data.item.customId"
              >
                <span class="font-weight-bold prk-fz-12">
                  {{ $t('components.prk_matrices.add_effect') }}
                </span>
              </pcg-btn>
            </div>
          </div>
        </template>
        <template v-slot:row-details="row">
          <matrices-details
              v-if="hideRow(row.item.courseEffectsSubItems.length, row.item.subjectNameId)"
              :customId="row.item.customId"
              modalId="course-effects-modal"
              :sub-items="row.item.courseEffectsSubItems"
              @courseEffectRemoved="courseEffectRemoved"
              @currentCustomId="customId = $event"
          />
        </template>
      </prk-table>

      <matrix-previous-year-effects :action="assignEffects"/>

      <course-effects-modal
          :excludedEffectsIds="excludedEffectsIds"
          :action="assignEffectsToSubjects"
      />
    </div>
  </div>
</template>

<script>
import MatricesDetails from './MatricesDetails'
import CourseEffectsModal from './modals/CourseEffectsModal'
import PrkTable from '../PrkTable'
import MatrixPreviousYearEffects from './modals/MatrixPreviousYearEffects'
import useMatrixSubjectNames from '../../composables/useMatrixSubjectNames'
import { toRefs, inject } from '@vue/composition-api'

export default {
  name: 'MatricesSubjects',
  setup (props, { root }) {
    const loading = inject('loading', false)
    const { matrixSubjectNames } = toRefs(props)
    const {
      selectedAll,
      selectedSubjectNameIds,
    } = useMatrixSubjectNames(matrixSubjectNames)
    return {
      loading,
      selectedAll,
      selectedSubjectNameIds,
    }
  },
  components: {
    MatrixPreviousYearEffects,
    PrkTable,
    MatricesDetails,
    CourseEffectsModal,
  },
  data () {
    return {
      customId: '',
      reload: false,
    }
  },
  props: {
    matrixSubjectNames: {
      type: Array,
      required: false,
    },
    kindId: {
      type: String,
      default: '',
    },
    typeId: {
      type: String,
      default: '',
    },
  },
  computed: {
    excludedEffectsIds () {
      if (!this.customId) return []
      const matrixSubjectName = this.matrixSubjectNames.find(data => data.customId === this.customId)
      if (!matrixSubjectName) return []
      const ids = matrixSubjectName.courseEffectsSubItems?.map(data => data.id)
      return ids || []
    },
    customFields () {
      return [
        {
          key: 'courseEffects',
          label: this.$t('components.prk_syllabus.table.course_effects'),
          tdClass: ['align-middle'],
        },
        {
          key: 'actions',
          label: '',
          tdClass: ['align-middle'],
        },
      ]
    },
    selectedSubjectNames () {
      return this.matrixSubjectNames.filter(item => item.selected)
    },
    selectedSubjectNameCustomIds () {
      return this.selectedSubjectNames.map(sub => sub.customId)
    },
  },
  watch: {
    selectedSubjectNameIds () {
      this.$emit('subject-names-was-selected', this.selectedSubjectNameIds)
    },
    year: {
      async handler () {
        if (this.year === '') return
        const { data: response } = await this.$api.get(
          'matrices',
          { academic_year: this.year },
        )
        this.matrixNames = response.map(matrix => {
          return {
            value: matrix.id,
            text: matrix.attributes.name,
          }
        })
      },
    },
  },
  methods: {
    hideRow (length, id) {
      if (length === 0) {
        this.matrixSubjectNames.find(data => data.subjectNameId === id)._showDetails = false
        return false
      }
      return true
    },
    async assignEffects (id, mode = '0') {
      this.$bvModal.hide('downloadModal')
      const { included: matrix } = await this.$api.get(`matrices/${id}`)

      const matrixSubjectNamesCopies = matrix.filter(data => {
        return data.type === 'matrix_subject_name'
      })

      for (const index in this.matrixSubjectNames) {
        if (mode === '0' && this.matrixSubjectNames[index].courseEffectsSubItems.length > 0) continue
        if (mode === '1' && this.matrixSubjectNames[index].courseEffectsSubItems.length > 0) {
          this.$emit('remove-all-effects', index)
        }

        const matrixSubjectNamesCopy = matrixSubjectNamesCopies
          .find(el => el.attributes.subjectNameId === this.matrixSubjectNames[index].subjectNameId)

        this._addEffectToSubject(matrixSubjectNamesCopy, index)
      }
    },
    selectAll (selected) {
      selected ? this.$selectAll(this.matrixSubjectNames) : this.$unselectAll(this.matrixSubjectNames)
      this.$emit('selected-matrix-subject-names', this.selectedSubjectNameCustomIds)
    },
    select () {
      this.$emit('selected-matrix-subject-names', this.selectedSubjectNameCustomIds)
    },
    async assignEffectsToSubjects (courseEffects) {
      const index = this.matrixSubjectNames
        .findIndex(matrixSubjectName => matrixSubjectName.customId === this.customId)
      if (~index) {
        courseEffects.forEach(effect => {
          this.$emit('add-effects', index, effect)
        })
        this.$bvModal.hide('course-effects-modal')
        this.reload = !this.reload
      }
    },
    courseEffectRemoved (effectId, matrixSubjectNameId) {
      const index = this.matrixSubjectNames
        .findIndex(matrixSubjectName => matrixSubjectName.customId === matrixSubjectNameId)
      if (~index) {
        if (this.matrixSubjectNames[index].hasAcceptedSyllabuses) {
          this.$toastr.i(this.$t('components.prk_syllabus.errors.cannot_delete'))
        } else {
          this.$emit('course-effect-removed', effectId, matrixSubjectNameId)
        }
      }
    },
    expandInfo (expanded) {
      if (expanded) {
        return this.$t('components.prk_matrices.collapse_effect')
      } else {
        return this.$t('components.prk_matrices.expand_effect')
      }
    },
    _addEffectToSubject (matrixSubjectNamesCopy, index) {
      if (matrixSubjectNamesCopy) {
        matrixSubjectNamesCopy.attributes.effects.map(effect => {
          if (!this.matrixSubjectNames[index].courseEffectsSubItems.find(effectSub => effectSub.id === effect.id)) {
            this.$emit('add-effects', index, {
              id: effect.id,
              symbol: effect.symbol,
              description: effect.description,
              toggleDetails: false,
            })
          }
        })
      }
    },
    getKeks (index) {
      const keks = []
      this.matrixSubjectNames[index].courseEffectsSubItems.forEach(kek => keks.push(kek.symbol))
      return keks.join(', ')
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['subjectNameId', '_showDetails', 'courseEffectsSubItems',
      'id', 'semesterIds', 'selected', 'semesterNumbers', 'hasSyllabuses', 'hasAcceptedSyllabuses', 'courseEffects', '_rowVariant',
      'msnId', 'syllabusBySemester', 'hasSyllabus']
  },
}
</script>

<style lang="scss" scoped>
.trans {
  transform: translateX(-7px)
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .subject-with-syllabuses {
    ::v-deep {
      .pcg-table-content {
        tbody {
          td {
            border-bottom: 1px solid $pcg-light-gray !important;
          }
        }
        .table-success {
          background-color: $pcg-another-blue;
        }
        .table-success > td {
          background-color: $pcg-another-blue;
        }
      }
    }
  }
}
</style>
