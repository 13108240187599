<template>
  <div class="syllabus-show">
    <syllabus-header
        :duringEducation="header.duringEducation"
        :training-cycle="header.trainingCycle"
        :academic-year="header.academicYear"
        :chooseLanguage="false"
        :status="header.status"
        class="mb-5"
    >
      <template #image>
        <b-col cols="12" sm="auto">
          <img :src="syllabusLogo" alt="missing.jpg"/>
        </b-col>
      </template>
      <template #persons>
        <persons/>
      </template>
    </syllabus-header>

    <syllabus-sections :sections.sync="tempSections"/>

    <prk-beam-syllabuses-admin v-if="currentRole === 'admin'"/>
    <prk-beam-syllabuses-educator
        v-else-if="currentRole === 'educator'"
        :status="header.status"
    />
    <prk-beam-syllabuses-student v-else/>
  </div>
</template>

<script>
import SyllabusHeader from '../../components/syllabus/PrkMainSyllabusHeader'
import Persons from '../../components/syllabus/PrkSyllabusHeaderPersons'
import PrkBeamSyllabusesAdmin from '../../components/beams/PrkBeamSyllabusesAdmin'
import PrkBeamSyllabusesEducator from '../../components/beams/PrkBeamSyllabusesEducator'
import PrkBeamSyllabusesStudent from '../../components/beams/PrkBeamSyllabusesStudent'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { provide } from '@vue/composition-api'
import { langProvider } from '../../composables/useSyllabusesShared'
import SyllabusSections from '../../components/syllabus/syllabus_fill/SyllabusSections'

const {
  mapState,
  mapActions,
} = createNamespacedHelpers('syllabusTemplate')

export default {
  name: 'SyllabusShow',
  setup () {
    langProvider()
    provide('status', 'to_fill')
    provide('statusesDataCompleted', ['to_verification', 'accepted'])
    provide('preview', true)
    provide('didacticMethods', [{
      formOfClass: 'Wykład',
      text: '',
    }, {
      formOfClass: 'Ćwiczenia',
      text: '',
    }])
    const effects = [
      {
        id: '1',
        educationalOutcome: '',
        effectContent: '',
        formOfClass: [],
        formOfCredits: [],
      },
    ]
    provide('effects', effects)
    const learningEffects = [
      {
        categoryId: '1',
        educationalOutcome: '',
        effectContentObj: '',
        formOfClassId: '1',
        id: '1',
        keks: [],
        kens: [],
      },
    ]
    provide('learningEffects', learningEffects)
    const categories = [{
      id: '1',
      name: 'Wiedza',
      shortcut: 'W',
    }]
    provide('categories', categories)
  },
  components: {
    SyllabusSections,
    PrkBeamSyllabusesStudent,
    PrkBeamSyllabusesAdmin,
    PrkBeamSyllabusesEducator,
    Persons,
    SyllabusHeader,
  },
  data () {
    return {
      header: {
        academicYear: '2019/2020',
        duringEducation:
            'Przykładowy tok, I stopień Stacjonarne, 2 rok, semestry III/IV',
        status: 'to_fill',
        trainingCycle: '2018-2021',
      },
      numberOfSection: 0,
      syllabus: null,
      tempSections: [],
      syllabusLogo: '',
    }
  },
  computed: {
    ...mapState(['sections', 'returnUrl']),
    ...mapGetters('auth', ['currentRole']),
  },
  created () {
    this.syllabus = JSON.parse(localStorage.getItem('syllabus'))
    this.syllabus.logo = this.$store.state.syllabusTemplate.syllabus.logo || this.syllabus.logo || {}
    if (this.$route.name === 'syllabuses_preview_path') {
      this.syllabusStatus = 'to_fill'
    }
    const sections = localStorage.getItem('sections')
    if (sections) {
      this.setSections(JSON.parse(sections))
    }
    this.tempSections = this.setSectionNumbers(
      JSON.parse(sections).map(section => section.visible ? section : []).flat(),
    )
    if (this.syllabus.logo instanceof File) {
      this.syllabusLogo = URL.createObjectURL(this.syllabus.logo)
    } else {
      this.syllabusLogo = this.syllabus.logo.url
    }
  },
  methods: {
    ...mapActions(['setSections']),
    setSectionNumbers (sections) {
      return sections.map(section => {
        if (section.number % 1 === 0) {
          this.numberOfSection += 1
          section.number = this.numberOfSection
          return section
        } else {
          const number = `${this.numberOfSection}.${section.number.slice(-1)}`
          return {
            ...section,
            number,
          }
        }
      },
      )
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.syllabus.syllabuses_templates'),
        subtitle: this.$t(`views.header.syllabus.${this.header.status}`),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  destroyed () {
    URL.revokeObjectURL(this.syllabusLogo)
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

img {
  max-width: 250px;
  max-height: 200px;
}

::v-deep {
  .pcg-table-container {
    border-radius: 5px;

    .prk-table-header {
      border-radius: 5px;
    }

    .pcg-table-content {
      border-radius: 5px;
    }
  }

  .box {
    border-radius: 5px !important;
    padding: 15px 30px 25px 30px !important;
  }
}
</style>
