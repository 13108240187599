import useSyllabusData from './useSyllabusData'

export default function (root) {
  const { sections, syllabus } = useSyllabusData(root)

  const addNewSection = () => {
    sections.value.push({
      additionalSection: true,
      component: 'empty-section',
      detailedComponent: '',
      elements: [],
      header: root.$t('general.additional_section'),
      showElements: true,
      showOnThePrintout: true,
      description: '',
      visible: true,
      visibleElements: {
        textField: true,
        table: true,
      },
    })
    _findAdditionalSectionAndChangeNumber()
  }

  const closeSection = (number) => {
    const sectionIndex = sections.value.findIndex(section => {
      return section.number === number
    })
    sections.value.splice(sectionIndex, 1)
    _findAdditionalSectionAndChangeNumber()
  }

  const mapSections = (included) => {
    included.filter(section => section.type === 'syllabus_section')
      .forEach((section, index) => {
        if (sections.value[index] === undefined) {
          addNewSection()
        }
        sections.value[index].id = section.id
        for (const sectionField in section.attributes) {
          sections.value[index][sectionField] = section.attributes[sectionField]
        }
      })
  }

  const mapSyllabus = (syllabusData) => {
    const attributes = syllabusData.attributes
    for (const key in attributes) {
      syllabus.value[key] = attributes[key]
    }
    syllabus.value.id = syllabusData.id
  }

  const mapSubsections = (included) => {
    const type = 'syllabus_element_subsection'
    included.filter(section => section.type === type).forEach((subSection) => {
      const sectionIndex = sections.value.findIndex(section => {
        return section.id === subSection.relationships.syllabusSection.data.id
      })
      if (parseFloat(sections.value[sectionIndex].number) !== 5.0) {
        sections.value[sectionIndex].elements.push({
          id: subSection.id, ...subSection.attributes,
        })
      }
    })
  }

  const mapBasicInformation = (included) => {
    const type = 'syllabus_basic_information'
    const basicInformation = included.find(section => section.type === type)
    sections.value[0].basicInformationId = basicInformation.id
  }

  const mapBasicInformationRow = (included) => {
    const type = 'syllabus_basic_information_row'
    included.filter(section => section.type === type).forEach((row, index) => {
      root.$set(sections.value[0].rows, index,
        {
          id: row.id,
          information: row.attributes.information,
          fill: sections.value[0].rows[index].fill,
          type: sections.value[0].rows[index].type,
          value: sections.value[0].rows[index].value,
          additional: sections.value[0].rows[index].additional,
          ...root.$_.omit(row.attributes, 'type', 'information', 'position', 'whence', 'value'),
        })
    })
  }

  const getConfig = () => {
    const config = {
      action: '',
      url: '',
    }
    if (root.$route.name === 'syllabuses_template_edit') {
      config.action = 'put'
      config.url = `syllabuses/${syllabus.value.id}`
    } else {
      config.action = 'post'
      config.url = 'syllabuses'
    }
    return config
  }

  const setDefaultDataForSubSection = (type) => {
    switch (type) {
      case 'checkbox':
        return { value: false }
      case 'one_line':
        return { value: '' }
      case 'text_field':
        return { value: '' }
      case 'table':
        return {
          value: [{
            label0: '',
            label1: '',
          }, {
            value0: '',
            value1: '',
          }],
        }
    }
  }

  const _findAdditionalSectionAndChangeNumber = () => {
    const newArray = sections.value.filter(section => {
      return section.additionalSection === true
    })
    let currentNumber = 8

    newArray.forEach(section => {
      section.number = currentNumber.toString()
      currentNumber += 1
    })
  }

  return {
    addNewSection,
    closeSection,
    getConfig,
    syllabus,
    sections,
    mapSections,
    mapSyllabus,
    mapSubsections,
    mapBasicInformation,
    mapBasicInformationRow,
    setDefaultDataForSubSection,
  }
}
