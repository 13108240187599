<template>
  <div v-if="header.key === 'value'">
    <span v-if="isLanguageOfLectureRow">
      {{ languageOfLecture }}
    </span>
    <span v-else-if="isLeadersRow">
      <prk-tags :value="leaders" without-remove />
    </span>
    <span v-else>
      {{ value }}
    </span>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkTags from '../../../../PrkTags'
const { mapState } = createNamespacedHelpers('syllabusFill')

export default {
  name: 'basic-information-about-subject-value',
  components: {
    PrkTags,
  },
  props: {
    header: { required: true, type: Object },
    information: { required: true, type: String },
    value: { type: [String, Number, Array], default: '' },
  },
  computed: {
    ...mapState(['leaders']),
    languageOfLecture () {
      return this.value === '0' ? 'Polski' : 'English'
    },
    isLanguageOfLectureRow () {
      return this.information === 'language'
    },
    isLeadersRow () {
      return this.information === 'leading_persons'
    },
  },
}
</script>
